import { AnyRoute } from '@tanstack/react-router';
import { UserPermissions } from '../routes/__root';
import { useAccountUser } from '../routes/_account';
import { AccountPrivilege } from '../utils/constants/privileges';

export function useRouteAccessControl(
  route: AnyRoute,
):
  | { match: true }
  | { match: false; permissions?: UserPermissions[keyof UserPermissions][number][]; privileges?: AccountPrivilege[] } {
  const { staticData } = route.options;
  const {
    permissions = [],
    account: { privileges = [] },
  } = useAccountUser();

  if (!(staticData?.privileges?.length || staticData?.permissions?.length)) {
    throw new Error(
      "useRouteAccessControl(): 'privileges' and/or 'permissions' are required when using this hook. " +
        "Define them in the 'staticData' of the route object.",
    );
  }
  const missingPrivileges = staticData.privileges?.filter((p) => !privileges.includes(p));
  const missingPermissions = staticData.permissions?.filter((p) => !permissions.includes(p));
  if (missingPrivileges?.length || missingPermissions?.length) {
    return { match: false, privileges: missingPrivileges, permissions: missingPermissions };
  }

  return { match: true };
}
