import { Card, CardContent } from '@mui/material';
import { AVAILABLE_PROVIDERS_FOR_BACKUP } from './constants';
import { Backups } from '.';
import { CloudProvider } from '../../../services/clustersApi';
import { Cluster, isClusterFreeTier, isClusterHybridCloud, PrivateRegion } from '../../../utils/cluster-utils';
import { UpgradeFreeToPaidBanner } from '../UpgradeFreeToPaidBanner';
import { CLOUD_PROVIDER_MAP } from '../constants';

type ClusterBackupsTabProps = {
  accountId: string;
  cluster: Cluster;
  privateRegion?: PrivateRegion;
};

const getProviderName = (cloudProvider: CloudProvider) => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER_MAP.AZURE:
      return 'Azure';
    case CLOUD_PROVIDER_MAP.GCP:
      return 'Google Cloud Platform';
    case CLOUD_PROVIDER_MAP.PRIVATE:
      return 'Hybrid Cloud';
    default:
      throw new Error('Unexpected cloud provider');
  }
};

export function ClusterBackupsTab({ cluster, privateRegion, accountId }: ClusterBackupsTabProps) {
  if (isClusterFreeTier(cluster)) {
    return <UpgradeFreeToPaidBanner cluster={cluster} accountId={accountId} />;
  }

  if (!AVAILABLE_PROVIDERS_FOR_BACKUP.includes(cluster.cloud_provider)) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          Backups feature is not available for clusters created on {getProviderName(cluster.cloud_provider)}.
        </CardContent>
      </Card>
    );
  }

  if (isClusterHybridCloud(cluster) && !privateRegion?.status?.capabilities.volumeSnapshot) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>Backups are only available if your Kubernetes cluster supports CSI Volume Snapshots.</CardContent>
      </Card>
    );
  }

  return <Backups defaultCluster={cluster} />;
}
