import { Check } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { ClusterStatusCard } from './Common/ClusterStatusCard';
import { WelcomeStepIcon } from './Common/WelcomeStepIcon';
import { CreateClusterStep } from './CreateClusterStep';
import { GenerateApiKeyStep } from './GenerateApiKeyStep';
import { GetStartedStep } from './GetStartedStep';
import { useWelcomeStepperContext } from './WelcomeStepperProvider';
import { useOnlineConnection } from '../../../../hooks/use-online-connection';
import { useWindowFocus } from '../../../../hooks/use-window-focus';
import { useAccountId } from '../../../../routes/_account';
import { useGetClustersByAccountIdQuery } from '../../../../services/clustersApi';
import { ApiKey } from '../../../../services/iamApi';
import { isClusterHealthy } from '../../../../utils/cluster-utils';

interface IOverviewWelcomeStepperProps {
  apiKeys?: ApiKey[];
  activeStep: number;
  onComplete: () => void;
}

export const OverviewWelcomeStepper = ({ apiKeys, activeStep, onComplete, ...other }: IOverviewWelcomeStepperProps) => {
  const { newCluster, setNewCluster, isClusterCreating, innerActiveStep, setInnerActiveStep, handleComplete } =
    useWelcomeStepperContext();

  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const accountId = useAccountId();
  const shouldPoll = isFocused && isOnline && isClusterCreating && !isClusterHealthy(newCluster);
  const { data: clusters } = useGetClustersByAccountIdQuery(
    { accountId },
    { refetchOnFocus: true, pollingInterval: shouldPoll ? 10_000 : 0, refetchOnMountOrArgChange: true },
  );

  const handleNext = () => {
    setInnerActiveStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    setInnerActiveStep(activeStep);
  }, [accountId, activeStep, setInnerActiveStep]);

  useEffect(() => {
    if (clusters && clusters.length > 0) {
      setNewCluster(clusters[0]);
    }
  }, [clusters, setNewCluster]);

  const steps = clusters
    ? [
        {
          label: 'Create Your Cluster',
          content: <CreateClusterStep clusters={clusters} onNext={handleNext} />,
        },
        {
          label: 'Generate API Key',
          content: <GenerateApiKeyStep onNext={handleNext} />,
        },
        {
          label: 'Get Started',
          content: <GetStartedStep onNext={() => handleComplete(onComplete)} />,
        },
      ]
    : [];

  return (
    <Box {...other}>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexGrow: 1,
          mb: 5,
        }}
      >
        <Grid item={true} container={true} xs={12}>
          <Grid
            item={true}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                mb: 3,
                textAlign: 'center',
              }}
              variant="h4"
            >
              Create Your First Cluster
            </Typography>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Grid sx={{ width: '70%' }}>
                <Stepper
                  activeStep={innerActiveStep}
                  orientation="horizontal"
                  sx={{
                    mb: 5,
                    width: '100%',
                    '& .MuiStepContent-root': { border: 'none' },
                    '& .MuiStepConnector-root span': {
                      marginTop: '-16px',
                    },
                  }}
                >
                  {steps.map((step, index) => (
                    <Step key={index} sx={{ position: 'relative' }}>
                      <StepLabel
                        StepIconComponent={({ active, completed, icon }) => {
                          const healthyCluster = isClusterHealthy(newCluster);

                          const isFirstStepProcessing =
                            index === 0 && ((active && isClusterCreating) || (!active && !healthyCluster));
                          const isLastStepHealthy = index === steps.length - 1 && active && healthyCluster;

                          let iconComponent;
                          if (isFirstStepProcessing) {
                            iconComponent = <CircularProgress size={24} color="inherit" />;
                          } else if (completed || isLastStepHealthy) {
                            iconComponent = <Check fontSize="small" />;
                          } else {
                            iconComponent = icon;
                          }

                          return (
                            <WelcomeStepIcon
                              active={active}
                              isLastStepHealthy={isLastStepHealthy}
                              completed={completed}
                              isFirstStepProcessing={isFirstStepProcessing}
                              icon={iconComponent}
                            />
                          );
                        }}
                        sx={{
                          flexDirection: 'column',
                          '& .MuiStepLabel-label': {
                            textAlign: 'center',
                            '& p': { fontSize: '1em', marginTop: '10px' },
                          },
                        }}
                      >
                        <Typography>{step.label}</Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              {(isClusterCreating || innerActiveStep > 0) && (
                <Grid item={true} xs={12} sx={{ width: '100%' }}>
                  <ClusterStatusCard />
                </Grid>
              )}

              {steps.map((step, index) => (
                <Grid key={index} item={true} xs={12} sx={{ width: '100%' }}>
                  {index === innerActiveStep && step.content}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
