import { Link, Typography } from '@mui/material';
import { useAccountId } from '../../../routes/_account';

export const buildSupportLink = (subject: string, body?: { accountId?: string; clusterBookingIds?: string[] }) => {
  const searchParams = new URLSearchParams();
  searchParams.set('subject', encodeURIComponent(subject));
  if (body) {
    if (body.accountId) {
      searchParams.set('account_id', encodeURIComponent(body.accountId));
    }
    if (body.clusterBookingIds) {
      searchParams.set('cluster_id', encodeURIComponent(body.clusterBookingIds.join(' ')));
    }
  }
  return decodeURIComponent(searchParams.toString());
};

const ContactSupportLink = ({
  subject = '',
  clusterBookingIds,
}: {
  subject?: string;
  clusterBookingIds?: string[];
}) => {
  const url = new URL('https://support.qdrant.io/support/tickets/new');
  const accountId = useAccountId();
  url.search = buildSupportLink(subject, { accountId, clusterBookingIds });

  return (
    <Link href={url.toString()} rel="noreferrer" target="_blank">
      contact support
    </Link>
  );
};

export const SugerAlertMessageIdAlreadyInUse = () => (
  <>
    <Typography component="p" variant="body1" pb={1}>
      Thank you for your registration through AWS/GCP Marketplace. However, this registration was already performed
      using a different account.
    </Typography>
    <Typography component="p" variant="body1" pb={1}>
      Please log in using the account to which this payment method is assigned. If you did not register this payment
      method, please make sure to <ContactSupportLink subject="Marketplace entitlement already taken" />.
    </Typography>
  </>
);

export const AlertInvalidPaymentDetails = () => (
  <>
    <Typography component="p" variant="body1" pb={1}>
      It appears there may be an issue with your payment details. This could be due to an expired credit card, or other
      payment related problems.
    </Typography>
    <Typography component="p" variant="body1" pb={1}>
      Please review your payment information carefully and try again. If the problem persists, you may want to contact
      your bank or try a different payment method.
    </Typography>
    <Typography component="p" variant="body1" pb={1}>
      If the problem persists, please <ContactSupportLink />.
    </Typography>
  </>
);

export const SugerAlertMessagePaymentAddedPendingSupportAction = () => (
  <>
    <Typography component="p" variant="body1" pb={1}>
      A new payment method has been added to your account. However, it requires additional steps to be taken.
    </Typography>
    <Typography component="p" variant="body1" pb={1}>
      Please <ContactSupportLink subject="Marketplace Billing Migration" />, we can then coordinate any necessary
      actions.
    </Typography>
  </>
);

export const SugerAlertMessagePaymentAddedWithBookingMigration = ({
  clusterBookingIds,
}: {
  clusterBookingIds: string[];
}) => (
  <>
    <Typography component="p" variant="body1" pb={1}>
      A new payment method has been added to your account. Your currently active cluster bookings are migrated. That
      means that your billing will be charged to this new payment method from now on.
    </Typography>
    <Typography component="p" variant="body1" pb={1}>
      Please{' '}
      <ContactSupportLink subject="Marketplace Billing Automatic Migration" clusterBookingIds={clusterBookingIds} /> if
      you have any questions.
    </Typography>
  </>
);
