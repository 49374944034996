import { createSelector } from '@reduxjs/toolkit';
import { StaticDataRouteOption, notFound } from '@tanstack/react-router';
import {
  AnyRoute,
  AnySearchValidator,
  BeforeLoadContextOptions,
  RouteContext,
} from '@tanstack/react-router/dist/esm/route';
import { RootRouteContext, UserPermissions } from '../routes/__root';
import { iamApi } from '../services/iamApi';
import { AccountPrivilege } from '../utils/constants/privileges';

export type Permission = UserPermissions[keyof UserPermissions][number];

export class RouteAccessControlError<TParams extends Record<string, unknown> | { _error: string }> extends Error {
  constructor(
    private routeParams: TParams,
    private privileges?: AccountPrivilege[],
    private permissions?: Permission[],
  ) {
    super();
  }

  /**
   * Returns the data that caused the error, which is part of the structure of the {error} prop of 'notFoundComponent'.
   * The root route receives this data and can use it to render a custom error component if necessary.
   */
  get data() {
    return {
      privileges: this.privileges,
      permissions: this.permissions,
      routeParams: this.routeParams,
    };
  }
}

export function isRouteAccessControlError<
  T extends { types: { allParams: Record<string, unknown> } } = {
    types: {
      allParams: {
        // eslint-disable-next-line max-len
        _error: "The generic parameter in isRouteAccessControlError<T> must be explicitely provided: 'isRouteAccessControlError<typeof Route>'";
      };
    };
  },
>(err: unknown): err is RouteAccessControlError<T['types']['allParams']> {
  return err instanceof RouteAccessControlError;
}

function getStoreData({
  context,
  params,
}: BeforeLoadContextOptions<AnyRoute, AnySearchValidator, { accountId: string }, unknown, () => RootRouteContext>) {
  return createSelector(iamApi.endpoints.getUserInfo.select({ account_id: params.accountId }), ({ data }) => ({
    permissions: data?.permissions ?? [],
    privileges: data?.account.privileges ?? [],
  }))(context.store.getState());
}

export function withRouteAccessControl<
  T extends {
    options: {
      staticData?: StaticDataRouteOption;
      beforeLoad?: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ctx: BeforeLoadContextOptions<any, any, any, any, any>,
      ) => // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      Promise<RouteContext> | RouteContext | void;
      onError?: (err: unknown) => void;
    };
  },
>(route: T): T {
  const originalBeforeLoad = route.options.beforeLoad;
  route.options.beforeLoad = function beforeLoad(
    ctx: BeforeLoadContextOptions<AnyRoute, AnySearchValidator, { accountId: string }, unknown, () => RootRouteContext>,
  ) {
    if (!(this.staticData?.privileges?.length || this.staticData?.permissions?.length)) {
      throw new Error(
        "withRouteAccessControl(): 'privileges' and/or 'permissions' are required when using this HOC. " +
          "Define them in 'staticData' within route.",
      );
    }
    const { privileges, permissions } = getStoreData(ctx);
    const missingPrivileges = this.staticData.privileges?.filter((p) => !privileges.includes(p));
    const missingPermissions = this.staticData.permissions?.filter((p) => !permissions.includes(p));

    if (missingPrivileges?.length || missingPermissions?.length) {
      // Throw next error instead of throw notFound(), otherwise onError() will not be called
      throw new RouteAccessControlError(ctx.params, missingPrivileges, missingPermissions);
    }
    return originalBeforeLoad?.(ctx);
  };
  const originalOnError = route.options.onError;
  route.options.onError = function onError(err) {
    // Give the original onError a chance to handle the error
    originalOnError?.(err);
    // If the error is a RouteAccessControlError, throw a notFound() error.
    if (isRouteAccessControlError(err)) {
      // As this is thrown within 'beforeLoad' as opposed to 'loader', only the root component can catch it.
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw notFound(err);
    }
  };
  return route;
}
