import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as HybridCloudEnvironmentsRoute } from './_hybrid-cloud-environments';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';

const LazyHybridCloudEnvironments = lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-environments" */ '../components/PrivateRegions/PrivateRegionsList').then(
    ({ PrivateRegionsList }) => ({
      default: PrivateRegionsList,
    }),
  ),
);

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environments',
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: '/',
  component: HybridCloudEnvironmentsComponent,
});

function HybridCloudEnvironmentsComponent() {
  return (
    <AuthenticatedDashboard>
      <PageFrame title="Hybrid Cloud Environments">
        <Box sx={{ marginTop: 3 }}>
          <Suspense fallback={<LoadingIndicator />}>
            <LazyHybridCloudEnvironments />
          </Suspense>
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
