import { useUserSegmentation } from '../routes/_account';

type NumericRange<
  Start extends number,
  End extends number,
  Arr extends unknown[] = [],
  Acc extends number = never,
> = Arr['length'] extends End
  ? Acc | Start | End
  : NumericRange<Start, End, [...Arr, 1], Arr[Start] extends undefined ? Acc : Acc | Arr['length']>;

type BucketCount = NumericRange<1, 100>;

/**
 * Returns a SHA-256 checksum of the string.
 */
async function getChecksum(str: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hash));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

/**
 * Returns the bucket for the current user - a number between 0 and # buckets - 1.
 * The value is deterministic and calculated based on the user ID and it represents the bucket the user is assigned to.
 *
 * The check for bucket is always buckets - 1, e.g.: 9 for 10 buckets, 3 for 4 buckets, etc.
 *
 * @example
 * ```ts
 * const bucket = getUserIdBucket({ userId, buckets: 2 });
 * const isControlGroup = bucket < 1;
 * const isExperimentGroup = bucket >= 1;
 * ```
 */
export async function getUserIdBucket({
  userId,
  buckets = 100,
  lruCache,
}: {
  userId: string;
  buckets?: BucketCount;
  lruCache?: Map<string, number>;
}): Promise<number> {
  if (lruCache?.has(userId)) {
    return lruCache.get(userId)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }
  const userBucket = Number(BigInt(`0x${await getChecksum(userId)}`) % BigInt(Math.min(buckets, 100)));
  lruCache?.set(userId, userBucket);
  return userBucket;
}

/**
 * Returns `true` if the user bucket falls into the phased rollout.
 *
 * **IMPORTANT**: once a phased rollout has started, never decrease the percentage of users in the phased rollout group.
 * If decreased, some users might be assigned to a different group and the rollout will be compromised.
 *
 * @example
 * const isUserInRolloutPhase = usePhasedRolloutForUser(10);
 * if (isUserInRolloutPhase) {
 *   // show the new feature
 * }
 */
export function usePhasedRolloutForUser(percentage: BucketCount) {
  const userBucket = useUserSegmentation();
  return userBucket < percentage;
}

/**
 * Returns an object with the keys `a`, `b`, `c`, `d` (depending on the number of buckets)
 * and the value is a boolean indicating if the user is in that group.
 * Returns `undefined` if the bucket is not yet available.
 *
 * **IMPORTANT**: once the bucket is assigned to a user, it should never change. If changed, the user might be assigned
 * to a different bucket and the experiment will be compromised.
 *
 * @example
 * const experimentGroup = await getExperimentGroupForUser(userId, 4);
 * if (experimentGroup.c) {
 *   // show the new feature
 * }
 */
export async function getExperimentGroupForUser(userId: string, buckets: 2): Promise<{ a: boolean; b: boolean }>;
export async function getExperimentGroupForUser(
  userId: string,
  buckets: 3,
): Promise<{
  a: boolean;
  b: boolean;
  c: boolean;
}>;
export async function getExperimentGroupForUser(
  userId: string,
  buckets: 4,
): Promise<{ a: boolean; b: boolean; c: boolean; d: boolean }>;
export async function getExperimentGroupForUser(userId: string, buckets: 2 | 3 | 4) {
  const userBucket = await getUserIdBucket({ userId, buckets });

  switch (buckets) {
    case 2:
      return { a: userBucket === 0, b: userBucket === 1 };
    case 3:
      return { a: userBucket === 0, b: userBucket === 1, c: userBucket === 2 };
    case 4:
      return { a: userBucket === 0, b: userBucket === 1, c: userBucket === 2, d: userBucket === 3 };
  }
}
