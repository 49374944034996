import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Outlet, createRootRouteWithContext, redirect } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { AnalyticsRoot } from '../components/Analytics/AnalyticsProvider';
import { GlobalAlertProvider } from '../components/Common/GlobalAlert';
import { getUTMParameters } from '../hooks/use-event-tracking';
import { META_TITLE, Meta } from '../router/Meta';
import { rootSearchSchema } from '../router/utils';
import { store } from '../store';
import { BASE_URL, WEB_STORAGE_PREFIX } from '../utils/constants';
import { Cookies } from '../utils/cookies';

export type AuthContext = Omit<Auth0ContextInterface, 'isLoading' | 'user'> &
  Required<Pick<Auth0ContextInterface, 'user'>>;

// TODO - These types will come from the OpenAPI spec soon...
export type UserPermissions = {
  authentication: (
    | 'read:api-keys'
    | 'write:api-keys'
    | 'delete:api-keys'
    | 'write:roles'
    | 'read:roles'
    | 'delete:roles'
  )[];
  cluster: (
    | 'read:clusters'
    | 'write:clusters'
    | 'delete:clusters'
    | 'admin:schedules'
    | 'read:schedules'
    | 'write:schedules'
    | 'delete:schedules'
  )[];
  payment: ('read:payment_information' | 'write:payment_information')[];
  account: (
    | 'write:account'
    | 'read:account'
    | 'delete:accounts'
    | 'write:invites'
    | 'read:invites'
    | 'delete:invites'
    | 'write:users'
    | 'read:users'
    | 'read:account_users'
    | 'delete:account_users'
  )[];
};

export type RootRouteContext = {
  queryClient: undefined;
  auth: AuthContext;
  store: typeof store;
};

export const SUGER_ENTITLEMENT_ID_COOKIE_KEY = `${WEB_STORAGE_PREFIX}suger_entitlement_id`;

export const Route = createRootRouteWithContext<RootRouteContext>()({
  meta: () => [
    {
      title: META_TITLE,
    },
  ],
  validateSearch: (search) => rootSearchSchema.parse(search),
  beforeLoad({ search, location }) {
    if (search.sugerEntitlementId) {
      Cookies.set(SUGER_ENTITLEMENT_ID_COOKIE_KEY, search.sugerEntitlementId);
      throw redirect({ search: (prev) => ({ ...prev, sugerEntitlementId: undefined }) });
    }
    return { utmParameters: getUTMParameters(new URL(location.href, BASE_URL).searchParams) };
  },
  component: RootComponent,
});

const DevToolsLazy = lazy(() => import('../router/DevTools').then(({ DevTools }) => ({ default: DevTools })));

function RootComponent() {
  return (
    <>
      <AnalyticsRoot>
        <Meta>
          <GlobalAlertProvider>
            <Outlet />
          </GlobalAlertProvider>
        </Meta>
      </AnalyticsRoot>
      {window.__QDRANT_CLOUD__.env === 'dev' && (
        <Suspense>
          <DevToolsLazy />
        </Suspense>
      )}
    </>
  );
}
