import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  Checkbox,
  SxProps,
} from '@mui/material';
import { ChangeEvent, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useAccountId } from '../../routes/_account';
import { isFetchApiError, isFetchMutationError, parseFetchError } from '../../services/helpers';
import {
  useInviteUserToAccountMutation,
  useGetInvitesByAccountIdQuery,
  useRevokeInviteMutation,
  InviteStatus,
} from '../../services/iamApi';
import { INVITE_STATUS } from '../../utils/constants/invites';
import { toISOString, toReadableDate } from '../../utils/invites-utils';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { ErrorBox } from '../Common/ErrorBox';
import { QdrantTable, RowType } from '../Common/QdrantTable';
import { Scrollbar } from '../Common/Scrollbar';
import { SeverityPill } from '../Common/SeverityPill';

const VALID_EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

type InviteInfo = {
  id: string;
  email: string;
  sentOn: string;
  status: InviteStatus;
};

/**
 * From an invite status it returns the Pill color.
 * @returns {'success' | 'primary' | 'warning' | 'error'}
 */
const getStatusColor = (status: InviteStatus) => {
  switch (status) {
    case INVITE_STATUS.ACCEPTED:
      return 'success';
    case INVITE_STATUS.DECLINED:
      return 'error';
    case INVITE_STATUS.PENDING:
      return 'warning';
    case INVITE_STATUS.REVOKED:
    default:
      return 'inactive';
  }
};

const INVITES_COLUMNS = [
  { title: 'Email', dataKey: 'email' },
  { title: 'Sent on', dataKey: 'sentOn' },
  { title: 'Status', dataKey: 'status', align: 'center' as const },
];

const FEEDBACK_MSG_CONTAINER_STYLE = {
  pt: 1,
  pl: 2,
  pb: 2,
};

export function Invites({ sx }: { sx: SxProps }) {
  const accountId = useAccountId();
  const { enqueueSnackbar } = useSnackbar();
  const [showNonPendingInvites, setShowNonPendingInvites] = useState(false);

  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();
  const [debounceTimeout, setDebounceTimeout] = useState<number>();

  // Invite email
  const [email, setEmail] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const isValidEmail = useMemo(() => !isDirty || VALID_EMAIL_REGEXP.test(email), [email, isDirty]);
  const {
    data: invites,
    isLoading: loadingInvites,
    isFetching: fetchingInvites,
    isError: errorLoadingInvites,
    refetch: refetchInvites,
  } = useGetInvitesByAccountIdQuery({ account_id: accountId });

  const [inviteUser, { isLoading }] = useInviteUserToAccountMutation();
  const [revokeInvite, { isLoading: revokingInvite }] = useRevokeInviteMutation();

  const onShowNonPendingInvitesChange = (_: ChangeEvent, checked: boolean) => {
    setShowNonPendingInvites(checked);
  };

  const invite = useCallback(async () => {
    const result = await inviteUser({ email, accountId });
    if (isFetchMutationError(result)) {
      const error = parseFetchError(result.error);
      const snackbarOptions = { variant: 'error' } as const;
      if (isFetchApiError(error)) {
        switch (error.code) {
          case 'E1046':
            return enqueueSnackbar('User is already a member of this account.', snackbarOptions);
          case 'E1047':
            return enqueueSnackbar('User already has active pending invite.', snackbarOptions);
          case 'E1048':
            return enqueueSnackbar(
              'Your invitation limit has been reached. ' +
                "Don't worry, it will reset some time " +
                'after your first declined/pending invite. ' +
                'Please try again later.',
              snackbarOptions,
            );
          case 'E1058':
            return enqueueSnackbar('The email domain and enterprise domains do not match', snackbarOptions);
          default:
        }
        // Previously, status == 400 was handled here, and the error detail shown to the user. This is no longer
        // the case because it doesn't scale, and users will fallback to the one you see after this switch.
      }
      return enqueueSnackbar('There was a problem sending the invite. Try again later.', snackbarOptions);
    }
    // Success branch
    setEmail('');
    setIsDirty(false);
    return enqueueSnackbar('Invite sent successfully', { variant: 'success' });
  }, [email, accountId, inviteUser, enqueueSnackbar]);

  // This handler could be simplified by lodash's debounce method.
  const onEmailInputKeyDown = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      window.clearTimeout(debounceTimeout);
      if (e.key === 'Enter') {
        if (isDirty && isValidEmail) {
          await invite();
        }
      } else {
        setDebounceTimeout(
          window.setTimeout(() => {
            setIsDirty(true);
          }, 500),
        );
      }
    },
    [invite, debounceTimeout, isDirty, isValidEmail],
  );

  const handleRevokeInvite = useCallback(
    async (email: string, inviteId: string) => {
      const result = await revokeInvite({ accountId, inviteId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem revoking the invite. Try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar(`Invite for ${email} revoked successfully`, { variant: 'success' });
      }
    },
    [revokeInvite, enqueueSnackbar, accountId],
  );

  const invitesRows: RowType<InviteInfo>[] = useMemo(() => {
    if (!invites) {
      return [];
    }
    let rows = [...invites];
    if (!showNonPendingInvites) {
      rows = rows.filter(({ status }) => status === INVITE_STATUS.PENDING);
    }
    return rows
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .map((invite) => {
        const { id, user_email: email, created_at: sentOn, status } = invite;
        const rowData: InviteInfo = { id, email, sentOn, status };
        return {
          email,
          sentOn: <span title={toISOString(sentOn)}>{toReadableDate(sentOn)}</span>,
          status: <SeverityPill color={getStatusColor(status)}>{status}</SeverityPill>,
          rowData,
        };
      });
  }, [invites, showNonPendingInvites]);

  const inviteRowActions = useMemo(
    () => [
      {
        name: 'Revoke',
        getHandler:
          ({ email, id }: InviteInfo) =>
          () =>
            showConfirmation({
              actionName: 'Revoke',
              title: `Revoke invite to ${email}`,
              content: 'The sent invite will be revoked.',
              actionHandler: () => handleRevokeInvite(email, id),
              withConfirm: false,
            }),
        isDisabled: ({ status }: InviteInfo) => status !== INVITE_STATUS.PENDING || revokingInvite,
      },
    ],
    [handleRevokeInvite, revokingInvite, showConfirmation],
  );

  const getInvitesBody = () => {
    if (loadingInvites) {
      return <Skeleton variant="rectangular" height={240} />;
    }

    if (errorLoadingInvites) {
      return (
        <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
          <ErrorBox retry={refetchInvites} />
        </Box>
      );
    }

    if (invitesRows.length) {
      return (
        <Box data-sentry-mask={true}>
          <QdrantTable<InviteInfo>
            columns={INVITES_COLUMNS}
            rows={invitesRows}
            rowActions={inviteRowActions}
            pagination={true}
            order={['sentOn', 'desc']}
            ariaLabel="Invites"
          />
        </Box>
      );
    }

    return (
      <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
        <Typography sx={{ mb: 1 }}>
          {showNonPendingInvites ? "You haven't sent any invites yet." : 'You have no pending requests.'}
        </Typography>
      </Box>
    );
  };

  const isTextboxDisabled = isLoading || loadingInvites || errorLoadingInvites;

  return (
    <Box sx={{ ...sx }} role="region" aria-labelledby="invite-members-title">
      <Card>
        <CardContent>
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ flexGrow: 1 }} variant="h6" id="invite-members-title">
                Invite members
                {!loadingInvites && fetchingInvites && <CircularProgress size={16} sx={{ ml: 1 }} />}
              </Typography>
              <FormControlLabel
                label="Show older invites"
                control={<Checkbox onChange={onShowNonPendingInvitesChange} checked={showNonPendingInvites} />}
              />
            </Box>
          </div>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <TextField
              data-sentry-mask={true}
              label="Email address"
              placeholder="Add the email of the member you want to invite"
              size="small"
              sx={{ flexGrow: 1 }}
              value={email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              error={!isValidEmail}
              onKeyDown={onEmailInputKeyDown}
              onBlur={() => setIsDirty(true)}
              onChange={(event) => setEmail(event.target.value)}
              disabled={isTextboxDisabled}
            />
            <Button
              onClick={invite}
              disabled={loadingInvites || isTextboxDisabled || !email || !isDirty || !isValidEmail}
              type="submit"
              variant="outlined"
              color="primary"
              endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon fontSize="small" />}
            >
              {isLoading ? 'Sending' : 'Send Invite'}
            </Button>
          </Box>
        </CardContent>

        <Scrollbar>{getInvitesBody()}</Scrollbar>
      </Card>
      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
    </Box>
  );
}
