import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountRoute, useAccountId } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { Backups } from '../components/Clusters/Backups';
import { ErrorBox } from '../components/Common/ErrorBox';
import { useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { Cluster } from '../utils/cluster-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Backups',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'backups',
  component: BackupsComponent,
});

const BackupsPageBody = ({
  clusters,
  refetchClusters,
  errorLoadingClusters,
  loadingClusters,
}: {
  clusters?: Cluster[];
  refetchClusters: () => void;
  errorLoadingClusters: boolean;
  loadingClusters: boolean;
}) => {
  if (errorLoadingClusters) {
    return <ErrorBox retry={errorLoadingClusters ? refetchClusters : undefined} />;
  }

  return <Backups availableClusters={clusters} loadingClusters={loadingClusters} sx={{ mt: 3 }} />;
};

function BackupsComponent() {
  const accountId = useAccountId();
  const {
    data: clusters,
    isError: errorLoadingClusters,
    isLoading: loadingClusters,
    refetch,
  } = useGetClustersByAccountIdQuery({ accountId });

  return (
    <AuthenticatedDashboard>
      <PageFrame title="Backups">
        <Box sx={{ marginTop: 3 }}>
          <BackupsPageBody
            clusters={clusters}
            refetchClusters={refetch}
            loadingClusters={loadingClusters}
            errorLoadingClusters={errorLoadingClusters}
          />
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
