import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
  useMediaQuery,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useState } from 'react';
import { ApiKeyEmptyState } from './ApiKeyEmptyState';
import { ApiKeyList } from './ApiKeyList';
import { ApiKeyLoadingState } from './ApiKeyLoadingState';
import { CreateApiKeyDialog } from './CreateApiKeyDialog';
import { NewApiKeyCodeSnippet } from './NewApiKeyCodeSnippet';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { Plus } from '../../icons/plus';
import { clusterCreateSearch } from '../../router/utils';
import { useAccountId } from '../../routes/_account';
import { Cluster, useGetClustersByAccountIdQuery } from '../../services/clustersApi';
import { useGetFeatureFlagsQuery } from '../../services/configApi';
import { useCreateApiKeyMutation, useGetApiKeysByAccountIdQuery } from '../../services/iamApi';
import { isClusterHybridCloud } from '../../utils/cluster-utils';
import { getUserMessage } from '../../utils/errorHandlingUtils';
import { ClusterCreationDisabledWarning } from '../Common/WarningTexts';

const mapClusterIdToCluster = (clusters: Cluster[]) => new Map(clusters.map((cluster) => [cluster.id, cluster]));

const getClustersByClusterIDs = (clusterIds: string[], clusters: Cluster[]) =>
  clusters.filter((cluster) => clusterIds.includes(cluster.id));

export function ClusterAccess({ sx, clusterId }: { sx?: SxProps; clusterId?: string }) {
  const accountId = useAccountId();
  const theme = useTheme();
  const navigate = useNavigate();
  const [createKeyDialogOpen, setCreateKeyDialogOpen] = useState(false);
  const [selectedClusterIds, setSelectedClusterIds] = useState<string[]>([]);
  const [createApiKey, { isLoading: isCreatingApiKeys }] = useCreateApiKeyMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [newApiKey, setNewApiKey] = useState<string | null>(null);
  // Breakpoint to show key creation dialog and new key dialog in full-screen mode on mobile devices
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const onNewApiKeyDialogClose = useCallback(() => {
    setNewApiKey(null);
    setSelectedClusterIds([]);
  }, []);

  const {
    data: apiKeys,
    isLoading: loadingApiKeys,
    isFetching: fetchingApiKeys,
  } = useGetApiKeysByAccountIdQuery({ account_id: accountId, cluster_id: clusterId });
  const { data: clusters, isLoading: loadingClusters } = useGetClustersByAccountIdQuery({ accountId });
  const loadingData = loadingApiKeys || loadingClusters;

  const { data } = useGetFeatureFlagsQuery();
  const isCreatingEnabled = data?.cluster_creation_enabled ?? true;
  const managedCloudClusters = clusters?.filter((cluster) => !isClusterHybridCloud(cluster));

  const handleCloseCreateDialog = useCallback(() => {
    if (!isCreatingApiKeys) {
      setCreateKeyDialogOpen(false);
    }
  }, [isCreatingApiKeys]);

  const selectClusters = useCallback((clusterIds: string[]) => {
    setSelectedClusterIds(clusterIds);
  }, []);

  const handleCreateApiKey = useCallback(
    async (clusterIDs?: string[]) => {
      const clusterIDsToUse = clusterIDs ?? selectedClusterIds;
      try {
        const { token } = await createApiKey({
          accountId,
          clusterIDs: clusterIDsToUse,
        }).unwrap();
        setNewApiKey(token);
        setCreateKeyDialogOpen(false);
      } catch (err) {
        enqueueSnackbar(getUserMessage(err), { variant: 'error' });
        setCreateKeyDialogOpen(false);
      }
    },
    [accountId, createApiKey, enqueueSnackbar, selectedClusterIds],
  );

  const handleOpenCreateDialog = useCallback(() => {
    if (!clusterId) {
      setSelectedClusterIds([]);
      setCreateKeyDialogOpen(true);
    } else {
      void handleCreateApiKey([clusterId]);
    }
  }, [handleCreateApiKey, clusterId]);

  const handleCreateClusterClick = useCallback(
    () =>
      navigate({
        to: '/accounts/$accountId/clusters/create',
        params: { accountId },
        search: clusterCreateSearch(),
      }),
    [accountId, navigate],
  );

  const hasClusters = managedCloudClusters?.length && managedCloudClusters.length > 0;
  const hasApiKeys = apiKeys?.length && apiKeys.length > 0;

  // When account has no api keys there is a button on the card
  const actionButtons = hasApiKeys
    ? [
        <Button
          key="create-api-key"
          startIcon={<Plus fontSize="small" />}
          variant="contained"
          disabled={(!loadingClusters && !hasClusters) || isCreatingApiKeys}
          onClick={handleOpenCreateDialog}
          endIcon={isCreatingApiKeys && <CircularProgress size={16} />}
        >
          Create
        </Button>,
      ]
    : undefined;

  return (
    <Box sx={{ ...sx }}>
      <Card>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {`Manage API keys and access to your ${clusterId ? 'cluster' : 'Managed Cloud clusters'} `}
              {!loadingData && fetchingApiKeys && <CircularProgress size={16} sx={{ ml: 1 }} />}
            </Box>
          }
          action={actionButtons}
        />
        <Divider />
        {loadingData && <ApiKeyLoadingState />}
        {!clusterId && !loadingClusters && !hasClusters && (
          <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ mb: 1 }} variant="h5">
              No Managed Cloud clusters created yet.
            </Typography>
            <Typography sx={{ mb: 1 }}>Create a cluster first and come back here to get access</Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCreateClusterClick}
              disabled={!isCreatingEnabled}
            >
              Create
            </Button>
            {!isCreatingEnabled && <ClusterCreationDisabledWarning sx={{ mr: 2 }} />}
          </Box>
        )}
        {!loadingData && hasClusters ? (
          hasApiKeys ? (
            <ApiKeyList apiKeys={apiKeys} clusterIdToClusterMap={mapClusterIdToCluster(managedCloudClusters)} />
          ) : (
            <ApiKeyEmptyState handleCreateClick={handleOpenCreateDialog} />
          )
        ) : null}
        {!loadingData && managedCloudClusters && (
          <CreateApiKeyDialog
            open={createKeyDialogOpen}
            accountId={accountId}
            onClose={handleCloseCreateDialog}
            clusters={managedCloudClusters}
            selectedClusterIds={selectedClusterIds}
            selectClusters={selectClusters}
            fullScreen={fullScreen}
            onSubmit={handleCreateApiKey}
            isCreatingApiKeys={isCreatingApiKeys}
          />
        )}

        {newApiKey && managedCloudClusters && (
          <NewApiKeyCodeSnippet
            apiKey={newApiKey}
            fullScreen={fullScreen}
            onClose={onNewApiKeyDialogClose}
            selectedClusters={getClustersByClusterIDs(
              clusterId ? [clusterId] : selectedClusterIds,
              managedCloudClusters,
            )}
          />
        )}
      </Card>
    </Box>
  );
}
