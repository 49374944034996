import { styled, ToggleButton, ToggleButtonGroup, alpha } from '@mui/material';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { CloudProviderIcon } from './CloudProviderIcon';
import { useRouteAccessControl } from '../../../../router/useRouteAccessControl';
import { useAccountUser } from '../../../../routes/_account';
import { Route as HybridCloudRoute } from '../../../../routes/hybrid-cloud';
import { CloudProvider } from '../../../../services/clustersApi';
import { PaymentInformation, useGetPaymentInformationQuery } from '../../../../services/paymentApi';
import { useStripeSessionIdParam } from '../../../Billing/hooks/use-stripe-session-id-param';
import {
  CLOUD_PROVIDERS,
  CLOUD_PROVIDER_CONFIG,
  CLOUD_PROVIDER_IMGS,
  CLOUD_PROVIDER_MAP,
  FREE_TIER_CLOUD_PROVIDER,
} from '../../constants';
import { ClusterTemplateType } from '../ClusterTemplateList';

const StyledToggleButton = styled(ToggleButton)(
  ({ theme: { palette } }) => `
    min-width: 40px;
    min-height: 40px;
    padding: 0;
    
    > * {
      box-sizing: content-box;
      padding: 11px;
    }

    &.Mui-selected {
      background: ${palette.mode === 'dark' ? palette.background.default : palette.background.paper} !important;
      border-color: ${alpha(palette.primary.light, 0.5)} !important;
    }
    &.Mui-disabled {
      pointer-events: all;
      cursor: default;
      > * {
        opacity: 0.6;
      }
    }
`,
);

type PaymentType = PaymentInformation['type_'];

/** Returns a map of available providers based on the current state (enabled/disabled) */
export const getAvailableProvidersMap = ({
  clusterTemplate,
  hasHybridCloudPrivilege,
  paymentType,
}: {
  clusterTemplate: ClusterTemplateType;
  hasHybridCloudPrivilege: boolean;
  paymentType?: PaymentType;
}) => {
  const cloudProviderEnv = window.__QDRANT_CLOUD__.env === 'production' ? 'production' : 'staging';

  return CLOUD_PROVIDERS.reduce((acc, provider) => {
    const { enabled } = CLOUD_PROVIDER_CONFIG.providers[provider][cloudProviderEnv];
    if (provider === CLOUD_PROVIDER_MAP.PRIVATE) {
      if (!hasHybridCloudPrivilege) {
        return acc;
      }
      return acc.set(provider, enabled);
    }
    if (clusterTemplate === 'free') {
      return acc.set(provider, provider === FREE_TIER_CLOUD_PROVIDER && enabled);
      /*
       * For the time being, we don't want to allow paid clusters on GCP based
       * on having a AWS Marketplace payment method, and viceversa.
       * When we introduce multiple payment methods, we will need to revisit this.
       */
    }
    if (
      (paymentType === 'suger_aws' && provider !== CLOUD_PROVIDER_MAP.AWS) ||
      (paymentType === 'suger_gcp' && provider !== CLOUD_PROVIDER_MAP.GCP) ||
      (paymentType === 'suger_azure' && provider !== CLOUD_PROVIDER_MAP.AZURE)
    ) {
      return acc.set(provider, false);
    }
    return acc.set(provider, enabled);
  }, new Map<CloudProvider, boolean>());
};

export const ClusterProviderList = memo(function ClusterProviderList({
  value,
  clusterTemplate,
  onChange,
}: {
  value: CloudProvider;
  clusterTemplate: ClusterTemplateType;
  onChange: (value: CloudProvider) => void;
}) {
  const { account } = useAccountUser();
  const { id: accountId } = account;
  const handleChange = useCallback(
    (_: unknown, value: CloudProvider | null) => {
      if (value !== null) {
        onChange(value);
      }
    },
    [onChange],
  );
  const { stripeSessionId } = useStripeSessionIdParam();
  // Loading payment information to pre-select provider
  const { data: paymentInfoData, isLoading: isPaymentInfoLoading } = useGetPaymentInformationQuery(
    { accountId },
    {
      skip: clusterTemplate === 'free' || Boolean(stripeSessionId),
      refetchOnFocus: true,
    },
  );
  const paymentType = paymentInfoData?.type_;

  const { match: hasHybridCloudPrivilege } = useRouteAccessControl(HybridCloudRoute);
  const availableProvidersMap = useMemo(
    () => getAvailableProvidersMap({ clusterTemplate, hasHybridCloudPrivilege, paymentType }),
    [clusterTemplate, hasHybridCloudPrivilege, paymentType],
  );

  useEffect(() => {
    if (clusterTemplate === 'free') {
      onChange(FREE_TIER_CLOUD_PROVIDER);
    } else if (paymentType) {
      // Pre-select provider based on payment information
      switch (paymentType) {
        case 'suger_aws':
          onChange(CLOUD_PROVIDER_MAP.AWS);
          break;
        case 'suger_gcp':
          onChange(CLOUD_PROVIDER_MAP.GCP);
          break;
        case 'suger_azure':
          onChange(CLOUD_PROVIDER_MAP.AZURE);
          break;
        default:
          break; // no pre-selection
      }
    }
  }, [onChange, paymentType, clusterTemplate]);

  return (
    <ToggleButtonGroup
      value={value}
      onChange={handleChange}
      color="primary"
      exclusive={true}
      disabled={isPaymentInfoLoading}
      aria-label="cloud provider choice"
    >
      {Array.from(availableProvidersMap).map(([provider, enabled]) => {
        const disabled = !enabled;
        let title = CLOUD_PROVIDER_IMGS[provider].tooltip;
        if (disabled) {
          title = `(Paid clusters only) ${title}`;
        }
        return (
          <StyledToggleButton key={provider} value={provider} disabled={disabled}>
            <CloudProviderIcon title={title} provider={provider} disabled={disabled} />
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
});

export const UnrestrictedClusterProviderList = memo(function UnrestrictedClusterProviderList({
  value,
  onChange,
}: {
  value: CloudProvider;
  onChange: (value: CloudProvider) => void;
}) {
  const handleChange = useCallback(
    (_: unknown, value: CloudProvider | null) => {
      if (value !== null) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup
      value={value}
      onChange={handleChange}
      color="primary"
      exclusive={true}
      aria-label="cloud provider choice"
    >
      {CLOUD_PROVIDERS.map((provider) => (
        <StyledToggleButton key={provider} value={provider} aria-label={provider}>
          <CloudProviderIcon title={CLOUD_PROVIDER_IMGS[provider].tooltip} provider={provider} fontSize="small" />
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
});
