import BackupIcon from '@mui/icons-material/Backup';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import StorageIcon from '@mui/icons-material/Storage';
import { Box, Divider, Drawer, Typography, useMediaQuery, Theme } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { memo, useMemo } from 'react';
import { AuthenticatedDashboardSidebarSection, SectionItem } from './AuthenticatedDashboardSidebarSection';
import { GetSupportLink } from './GetSupportLink';
import { Logo } from './Logo';
import { CreditCard as CreditCardIcon } from '../../icons/credit-card';
import { Home as HomeIcon } from '../../icons/home';
import { HybridCloudIconFilled } from '../../icons/hybrid-cloud-icon-filled';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { router } from '../../router';
import { useAccountId } from '../../routes/_account';
import { Route as BackupsRoute } from '../../routes/backups';
import { Route as BillingRoute } from '../../routes/billing';
import { Route as CloudAccessRoute } from '../../routes/cloud-access';
import { Route as ClustersRoute } from '../../routes/clusters';
import { Route as DataAccessRoute } from '../../routes/data-access';
import { Route as HybridCloudRoute } from '../../routes/hybrid-cloud';
import { Route as OverviewRoute } from '../../routes/overview';
import { Route as ProfileRoute } from '../../routes/profile';
import { Scrollbar } from '../Common/Scrollbar';
import { SocialFooter } from '../Common/SocialFooter';

const SECTIONS = {
  OVERVIEW: 'overview',
  CLUSTERS: 'clusters',
  HYBRID_CLOUD: 'hybrid-cloud',
  BACKUPS: 'backups',
  DATA_ACCESS: 'data-access',
  ACCESS_MANAGEMENT: 'access-management',
  BILLING: 'billing',
  PROFILE: 'profile',
} as const;

const getSections = (skippedSections: string[], accountId: string): { title: string; items: SectionItem[] }[] => [
  {
    title: 'Dashboard',
    items: [
      {
        key: SECTIONS.OVERVIEW,
        title: 'Overview',
        href: router.buildLocation({ to: OverviewRoute.to, params: { accountId } }).href,
        icon: <HomeIcon fontSize="small" />,
      },
      {
        key: SECTIONS.CLUSTERS,
        title: 'Clusters',
        href: router.buildLocation({ to: ClustersRoute.to, params: { accountId } }).href,
        icon: <StorageIcon fontSize="small" />,
      },
      {
        key: SECTIONS.HYBRID_CLOUD,
        title: 'Hybrid Cloud',
        href: router.buildLocation({ to: HybridCloudRoute.to, params: { accountId } }).href,
        icon: <HybridCloudIconFilled fontSize="small" />,
      },
      {
        key: SECTIONS.BACKUPS,
        title: 'Backups',
        href: router.buildLocation({ to: BackupsRoute.to, params: { accountId } }).href,
        icon: <BackupIcon fontSize="small" />,
      },
      {
        key: SECTIONS.DATA_ACCESS,
        title: 'Data Access Control',
        href: router.buildLocation({ to: DataAccessRoute.to, params: { accountId } }).href,
        icon: <KeyIcon fontSize="small" />,
      },
      {
        key: SECTIONS.ACCESS_MANAGEMENT,
        title: 'Access Management',
        href: router.buildLocation({ to: CloudAccessRoute.to, params: { accountId } }).href,
        icon: <GroupsIcon fontSize="small" />,
      },
    ].filter(({ key }) => !skippedSections.includes(key)),
  },
  {
    title: 'Settings',
    items: [
      {
        key: SECTIONS.BILLING,
        title: 'Billing',
        href: router.buildLocation({ to: BillingRoute.to, params: { accountId } }).href,
        icon: <CreditCardIcon fontSize="small" />,
      },
      {
        key: SECTIONS.PROFILE,
        title: 'Profile',
        href: router.buildLocation({ to: ProfileRoute.to, params: { accountId } }).href,
        icon: <UserCircleIcon fontSize="small" />,
      },
    ],
  },
];

export const AuthenticatedDashboardSidebar = memo(function AuthenticatedDashboardSidebar({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) {
  const accountId = useAccountId();
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const sections = useMemo(() => getSections([], accountId), [accountId]);

  const content = (
    <>
      <Scrollbar
        ariaLabel="Sidebar"
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div>
            <Box sx={{ p: 3 }}>
              <Link to="/accounts/$accountId/overview" params={{ accountId }}>
                <Logo
                  style={{
                    height: 'auto',
                    width: '70%',
                  }}
                />
              </Link>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3,
            }}
          />
          <Box sx={{ flexGrow: 1 }} role="navigation" aria-label="Main">
            {sections.map((section) => (
              <AuthenticatedDashboardSidebarSection
                key={section.title}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                {...section}
              />
            ))}
          </Box>
          <Divider
            sx={{
              borderColor: '#2D3748', // dark divider
            }}
          />
          <Box sx={{ p: 2 }}>
            <Typography color="neutral.100" variant="subtitle2" sx={{ mb: 2 }}>
              Need Help?
            </Typography>
            <GetSupportLink />
            <Box sx={{ mt: 5, sm: 12, md: 12 }}>
              <Typography color="neutral.500" variant="body2">
                Join the community
              </Typography>
              <SocialFooter includeEmail={true} />
            </Box>
          </Box>
        </Box>
      </Scrollbar>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={true}
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
});
