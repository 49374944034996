import { Box, Typography } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { Suspense, lazy, useCallback } from 'react';
import { useAccountId } from './_account';
import { Route as HybridCloudEnvironmentRoute } from './_hybrid-cloud-environment';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { Link } from '../router/Link';
import { MIDDOT_SYMBOL } from '../utils/constants';

const LazyPrivateRegionForm = lazy(() =>
  import(/* webpackChunkName: "private-region-form" */ '../components/PrivateRegions/PrivateRegionForm').then(
    ({ PrivateRegionForm }) => ({
      default: PrivateRegionForm,
    }),
  ),
);

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud Environment ${MIDDOT_SYMBOL} Edit`,
    },
  ],

  getParentRoute: () => HybridCloudEnvironmentRoute,
  path: 'edit',
  component: HybridCloudEnvironmentEditComponent,
});

function HybridCloudEnvironmentEditComponent() {
  const accountId = useAccountId();
  const { hybridCloudEnvId } = Route.useParams();
  const navigate = useNavigate();

  const onFormSuccess = useCallback(
    (hybridCloudEnvId: string) =>
      navigate({
        to: '/accounts/$accountId/hybrid-cloud-environments/$hybridCloudEnvId',
        params: {
          accountId,
          hybridCloudEnvId,
        },
      }),

    [navigate, accountId],
  );

  const breadcrumbs = [
    <Link
      key="1"
      to="/accounts/$accountId/hybrid-cloud"
      params={{ accountId }}
      sx={(theme) => ({
        textDecoration: 'none',
        color: theme.palette.text.primary,
      })}
    >
      Hybrid Cloud Environments
    </Link>,
    <Typography key="2" color="text.primary">
      {!hybridCloudEnvId ? 'Create new Hybrid Cloud Environment' : 'Edit Hybrid Cloud Environment'}
    </Typography>,
  ];

  return (
    <AuthenticatedDashboard>
      <PageFrame title="Hybrid Cloud Environments" breadcrumbs={breadcrumbs}>
        <Box sx={{ marginTop: 3 }}>
          <Suspense fallback={<LoadingIndicator />}>
            <LazyPrivateRegionForm onSuccess={onFormSuccess} />
          </Suspense>
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
