import styled from '@emotion/styled';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { useState, ChangeEvent } from 'react';
import { useTrackInteractionEvent } from '../../../../../hooks/use-event-tracking';
import { useSnackbar } from '../../../../../hooks/use-qdrant-snackbar';
import { useAccountId } from '../../../../../routes/_account';
import { useCreateClusterMutation } from '../../../../../services/clustersApi';
import { parseFetchError } from '../../../../../services/helpers';
import { getUserMessage } from '../../../../../utils/errorHandlingUtils';
import { CLUSTER_NAME_HELPER_TXT, validators } from '../../../../../utils/form-utils';
import { IS_PROD } from '../../../../../utils/helpers';
import { CLOUD_PROVIDER_CONFIG, FREE_TIER_CLOUD_PROVIDER } from '../../../../Clusters/constants';
import { ResourceIcons } from '../Common/ResourceIcons';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

const env = IS_PROD ? 'production' : 'staging';
const providerConfig = CLOUD_PROVIDER_CONFIG.providers[FREE_TIER_CLOUD_PROVIDER][env];
const ctaLabel = 'Create Free Cluster';

type ValidationError = {
  issues: { message: string }[];
};
const Img = styled.img`
  display: block;
  margin: 0 auto;
`;

export const CreateFreeClusterForm = () => {
  const { freeBookingPackage, setIsClusterCreating, setClusterName, clusterName } = useWelcomeStepperContext();
  const accountId = useAccountId();
  const [addCluster] = useCreateClusterMutation();
  const { enqueueSnackbar } = useSnackbar();
  const trackAddFreeCluster = useTrackInteractionEvent({
    label: ctaLabel,
    location: `${ctaLabel} Card (Horizontal)`,
    action: 'clicked',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.value;
      setClusterName(value);
      validators.clusterName.parse(value);
      setErrorMessage('');
    } catch (errors) {
      if (Array.isArray((errors as ValidationError).issues)) {
        const errorMsgs = (errors as ValidationError).issues.map(({ message }) => message);
        setErrorMessage(errorMsgs.join('. '));
      }
    }
  };

  const handleAddFreeCluster = async () => {
    try {
      validators.clusterName.parse(clusterName);
    } catch (errors) {
      if (Array.isArray((errors as ValidationError).issues)) {
        const errorMsgs = (errors as ValidationError).issues.map(({ message }) => message);
        setErrorMessage(errorMsgs.join('. '));
        return;
      }
    }
    trackAddFreeCluster();

    if (!freeBookingPackage) {
      enqueueSnackbar('No free cluster available', { variant: 'error' });
      return;
    }

    if (errorMessage || clusterName.trim().length === 0) {
      return;
    }

    try {
      setIsClusterCreating(true);

      if (freeBookingPackage.id) {
        await addCluster({
          name: clusterName,
          cloud_provider: FREE_TIER_CLOUD_PROVIDER,
          cloud_region: providerConfig.defaultRegion,
          configuration: {
            num_nodes: 1,
            num_nodes_max: 1,
            node_configuration: {
              package_id: freeBookingPackage.id,
            },
          },
          account_id: accountId,
        }).unwrap();
      }
    } catch (err) {
      setIsClusterCreating(false);
      enqueueSnackbar(getUserMessage(parseFetchError(err)));
    }
  };

  return (
    <Grid
      item={true}
      xs={12}
      md={6}
      sx={{
        flexGrow: 1,
      }}
    >
      <Card
        sx={{
          px: 5,
          py: 4,
        }}
      >
        <Img src="/cluster-flow-free-cluster-icon.svg" alt="Free Cluster Icon" />
        <CardHeader
          sx={{
            px: 0,
            pt: 3,
            pb: 1,
            '& span': { fontSize: '1.25em' },
          }}
          title="Name your free cluster"
        />
        <CardContent sx={{ px: 0, pt: 0, mt: 0, pb: 1 }}>
          <FormControl
            fullWidth={true}
            margin="dense"
            sx={{
              marginTop: '0px',
            }}
          >
            <TextField
              error={errorMessage !== ''}
              fullWidth={true}
              label="Cluster name"
              margin="normal"
              name="name"
              onInput={onChange}
              type="text"
              value={clusterName}
              variant="outlined"
              inputProps={{ maxLength: 64, minLength: 4 }}
            />
            <FormHelperText error={errorMessage !== ''}>{errorMessage || CLUSTER_NAME_HELPER_TXT}</FormHelperText>
          </FormControl>
          <Box mt={3}>
            <ResourceIcons />
          </Box>
        </CardContent>
        <CardActions sx={{ px: 0, pb: 0 }}>
          <Button type="submit" variant="contained" color="secondary" onClick={handleAddFreeCluster}>
            {ctaLabel}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
