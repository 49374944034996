import { useRouteContext } from '@tanstack/react-router';

/**
 * Returns the current authentication context from the router state.
 */
export const useAuth = () =>
  useRouteContext({
    from: '__root__',
    select: ({ auth }) => auth,
  });

/**
 * Returns the current utm parameters from the router state.
 */
export const useUtmParameters = () =>
  useRouteContext({
    from: '__root__',
    select: ({ utmParameters }) => utmParameters,
  });
