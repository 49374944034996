import { Box, Typography } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { useLocation } from '@tanstack/react-router';
import { useEffect } from 'react';
import { Route as RootRoute } from './__root';
import { useAuth } from '../router/hooks';
import { BASE_URL } from '../utils/constants';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Signing out ...',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'logout',
  component: LogoutComponent,
});

function LogoutComponent() {
  const { logout, isAuthenticated } = useAuth();
  const { searchStr } = useLocation();

  useEffect(() => {
    void logout({ logoutParams: { returnTo: `${BASE_URL}?${searchStr}` } });
  }, [logout, searchStr]);

  return isAuthenticated ? (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="20vh">
      <Typography>Signing out ...</Typography>
    </Box>
  ) : null;
}
