import { styled, Breadcrumbs, Typography } from '@mui/material';
import { createRoute, redirect } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { RootRouteContext } from './__root';
import { getAccountUser, useAccountId } from './_account';
import { Route as ClustersRoute } from './_clusters';
import { AuthenticatedDashboardAppBar } from '../components/Authenticated/AuthenticatedDashboardNavbar';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { enqueueSnackbar } from '../hooks/use-qdrant-snackbar';
import { Link } from '../router/Link';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '../router/utils';
import { clusterApi } from '../services/clustersApi';
import { configApi } from '../services/configApi';
import { AccountPrivilege } from '../utils/constants/privileges';

export function getMaxAllowedClusters(availablePrivileges: AccountPrivilege[]) {
  const defaultMaxClusters = 10;
  const requiredPrivileges = ['MAX_NUM_CLUSTERS_20', 'MAX_NUM_CLUSTERS_30', 'MAX_NUM_CLUSTERS_50'] as const;
  const privilegeToMaxClusters = new Map<AccountPrivilege, number>([
    ['MAX_NUM_CLUSTERS_20', 20],
    ['MAX_NUM_CLUSTERS_30', 30],
    ['MAX_NUM_CLUSTERS_50', 50],
  ]);

  return requiredPrivileges.reduce(
    (acc, val) => (availablePrivileges.includes(val) ? Math.max(acc, privilegeToMaxClusters.get(val) ?? 0) : acc),
    defaultMaxClusters,
  );
}

export async function isClusterCreationEnabled(store: RootRouteContext['store']) {
  const featureFlags = await store.dispatch(configApi.endpoints.getFeatureFlags.initiate()).unwrap();

  return featureFlags.cluster_creation_enabled;
}

export function getClustersByAccountId(store: RootRouteContext['store'], accountId: string) {
  return store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ accountId })).unwrap();
}

export const Route = createRoute({
  meta: () => [
    {
      title: 'Create cluster',
    },
  ],
  getParentRoute: () => ClustersRoute,
  path: 'create',
  validateSearch: (search) => clusterCreateSearchSchema.and(clusterSetupSearchSchema).parse(search),
  async beforeLoad({ params: { accountId }, context }) {
    const [clusterCreationEnabled, accountUser, clusters] = await Promise.all([
      isClusterCreationEnabled(context.store),
      getAccountUser(context.store, { account_id: accountId }),
      getClustersByAccountId(context.store, accountId),
    ]);
    if (!clusterCreationEnabled) {
      enqueueSnackbar({ message: 'Cluster creation is currently disabled.', variant: 'warning' });
      throw redirect({ to: '/accounts/$accountId/clusters', params: { accountId } });
    }
    const accountPrivileges = accountUser.account.privileges;
    if (accountPrivileges) {
      if (clusters.length >= getMaxAllowedClusters(accountPrivileges)) {
        enqueueSnackbar({
          message: (
            <>
              You have reached the maximum number of clusters allowed for your account.
              <br />
              Please contact support if you wish to increase your cluster limit.
            </>
          ),
          variant: 'warning',
        });
        throw redirect({ to: '/accounts/$accountId/clusters', params: { accountId } });
      }
    }
  },
  component: ClusterCreateComponent,
});

const LazyClusterCreate = lazy(() =>
  import(/* webpackChunkName: "cluster-create" */ '../components/Clusters/ClusterSetup/ClusterCreate').then(
    ({ ClusterCreate }) => ({ default: ClusterCreate }),
  ),
);

const StyledHeader = styled(AuthenticatedDashboardAppBar, { shouldForwardProp: (prop) => prop !== 'noSidebar' })`
  flex-direction: row;
  align-items: center;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme }) => `
    margin-inline: auto;
    padding: 0 ${theme.spacing(5)};
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    flex: 1;
  `,
);

const StyledMain = styled('main')(
  ({ theme }) => `
    margin: ${theme.spacing(5)} auto 0;
    padding: ${theme.spacing(8)} ${theme.spacing(5)} 0;
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    min-height: 100vh;
  `,
);

function ClusterCreateComponent() {
  const accountId = useAccountId();

  return (
    <>
      <StyledHeader noSidebar={true}>
        <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
          <Link key="1" to="/accounts/$accountId/clusters" params={{ accountId }}>
            Clusters
          </Link>
          <Typography key="2" color="text.primary">
            Create new cluster
          </Typography>
        </StyledBreadcrumbs>
      </StyledHeader>
      <StyledMain>
        <Suspense fallback={<LoadingIndicator />}>
          <LazyClusterCreate accountId={accountId} />
        </Suspense>
      </StyledMain>
    </>
  );
}
