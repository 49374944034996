import { Box, Typography, Avatar, CardHeader, Card, Divider, CardContent, Button } from '@mui/material';
import { createRoute, redirect } from '@tanstack/react-router';
import { Route as AccountRoute, PathlessRoute as PathlessAccountRoute, getAccountUser } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { DeleteAccountDangerZoneAction } from '../components/Profile/DeleteAccountDangerZoneAction';
import { PendingRequests } from '../components/Profile/PendingRequests';
import { UserSettings } from '../components/Profile/Settings';
import { UserAccounts } from '../components/Profile/UserAccounts';
import { useCookieHub } from '../contexts/cookie-hub-context';
import { useAuth } from '../router/hooks';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Profile',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'profile',
  component: ProfileComponent,
});

export const MaskingRoute = createRoute({
  getParentRoute: () => PathlessAccountRoute,
  path: 'profile',
  async loader({ parentMatchPromise, context: { store } }) {
    await parentMatchPromise; // wait for PathlessAccountRoute to load
    const {
      account: { id: accountId },
    } = await getAccountUser(store);
    throw redirect({ to: '/accounts/$accountId/profile', params: { accountId } });
  },
});
function ProfileComponent() {
  const { user } = useAuth();
  const userName = user.name?.length === 0 ? 'Anonymous' : user.name;
  const cookieHub = useCookieHub();

  return (
    <AuthenticatedDashboard>
      <PageFrame title="Profile">
        <Card id="user-details">
          <CardHeader title="User details" />
          <Divider />
          <CardContent>
            <Box sx={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <Avatar sx={{ width: 128, height: 128 }} id="user-avatar" alt={userName} src={user.picture} />
              <Box>
                <Typography variant="h6" color="primary">
                  Name
                </Typography>
                <Typography variant="inherit" data-sentry-mask={true} data-testid="profile-user-name">
                  {userName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  Email
                </Typography>
                <Typography variant="inherit" data-sentry-mask={true} data-testid="profile-email-address">
                  {user.email ?? '-'}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <PendingRequests />
        <UserAccounts />
        <Card sx={{ mt: 2 }}>
          <CardHeader title="Visual preferences" />
          <Divider />
          <CardContent>
            <UserSettings />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Privacy preferences" />
          <Divider />
          <CardContent>
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}>
              <Button variant="outlined" onClick={cookieHub.openDialog} disabled={!cookieHub.isLoaded}>
                Modify cookie consent settings
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Box mt={2}>
          <DeleteAccountDangerZoneAction />
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
