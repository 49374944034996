import { zodResolver } from '@hookform/resolvers/zod';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useChangeAccountNameMutation } from '../../services/iamApi';
import { ZERO_WIDTH_SPACE } from '../../utils/constants';
import { validators } from '../../utils/form-utils';

const MAX_LENGTH = 64;

const schema = z.object({ name: validators.name.max(MAX_LENGTH) });

type FormSchema = z.infer<typeof schema>;

export const EditAccountNameDialog = ({
  accountId,
  value,
  onClose,
}: {
  accountId: string;
  value: string;
  onClose: () => void;
}) => {
  const [changeAccountName, { isLoading }] = useChangeAccountNameMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    async ({ name }: FormSchema) => {
      try {
        await changeAccountName({ name, account_id: accountId }).unwrap();
        enqueueSnackbar('You have been successfully updated the account name.', { variant: 'success' });
        onClose();
      } catch {
        enqueueSnackbar('There was a problem updating the account name. Try again later.', { variant: 'error' });
      }
    },
    [changeAccountName, enqueueSnackbar, accountId, onClose],
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<FormSchema>({
    defaultValues: { name: value },
    resolver: zodResolver(schema),
    mode: 'all',
  });

  const name = watch('name');

  return (
    <Dialog
      id="edit-account-name-dialog"
      maxWidth="lg"
      open={true}
      onClose={() => !isLoading && onClose()}
      aria-labelledby="Dialog - Edit Account name"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle>Edit account name</DialogTitle>
      <DialogContent>
        <Box sx={{ maxWidth: 250 }}>
          <FormControl sx={{ mt: 2, minWidth: 250 }} margin="dense">
            <TextField
              {...register('name')}
              autoFocus={true}
              id="accountName"
              data-sentry-mask={true}
              aria-describedby="Account name"
              fullWidth={true}
              error={errors.name !== undefined}
              helperText={errors.name?.message ?? ZERO_WIDTH_SPACE}
              inputProps={{ maxLength: MAX_LENGTH }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => !isLoading && onClose()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
          sx={{ flex: 1 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isLoading || name.trim() === value || !isValid}
          variant="outlined"
          color="primary"
          endIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon fontSize="small" />}
          sx={{ flex: 1 }}
        >
          {isLoading ? 'Saving' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
