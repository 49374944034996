import { Alert, Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { ReactElement, memo, useEffect } from 'react';
import { Invoices } from './Invoices';
import { LazyBillingCycles } from './LazyBillingCycles';
import { LazyPaymentInformationForm } from './LazyPaymentInformationForm';
import { PaymentConnected } from './PaymentConnected';
import { PaymentConnectedStripeCallback } from './PaymentConnectedStripeCallback';
import { PaymentProviders } from './PaymentProviders';
import { PaymentTaxIdForm } from './PaymentTaxIdForm';
import { PAYMENT_INFO_SECTION_ELEMENT_ID } from './hooks/use-create-marketplace-redirect';
import { useScrollToPaymentRedirect } from './hooks/use-scroll-to-payment-redirect';
import { useStripeSessionIdParam } from './hooks/use-stripe-session-id-param';
import { isBillingInfoProvided, isBillingTaxSupportedCountry, isPaymentMethodProvided } from './payment-utils';
import { useAccountUser } from '../../routes/_account';
import { useGetPaymentInformationQuery } from '../../services/paymentApi';
import { ErrorBox } from '../Common/ErrorBox';

type PaymentSectionProps =
  | { paymentInfoOnly?: never }
  | {
      onAccountPaymentInfoAvailable?: () => void;
      paymentInfoOnly: true;
    };

export const PaymentSection = memo(function PaymentSection(props: PaymentSectionProps) {
  const { account } = useAccountUser();
  const { id: accountId } = account;
  const { stripeSessionId } = useStripeSessionIdParam();
  const { data, isLoading, isError, refetch } = useGetPaymentInformationQuery(
    { accountId },
    {
      skip: Boolean(stripeSessionId),
      refetchOnFocus: true,
    },
  );
  useScrollToPaymentRedirect();

  const paymentMethodProvided = isPaymentMethodProvided(account, data);
  const billingInfoProvided = isBillingInfoProvided(account, data);
  const onAccountPaymentInfoAvailable = 'onAccountPaymentInfoAvailable' in props && props.onAccountPaymentInfoAvailable;

  useEffect(() => {
    if (onAccountPaymentInfoAvailable && paymentMethodProvided && billingInfoProvided) {
      onAccountPaymentInfoAvailable();
    }
  }, [onAccountPaymentInfoAvailable, paymentMethodProvided, billingInfoProvided]);

  if (onAccountPaymentInfoAvailable && (isLoading || (paymentMethodProvided && billingInfoProvided))) {
    return <Skeleton variant="rounded" height={52} />;
  }

  let content: ReactElement | null = null;
  if (stripeSessionId) {
    content = <PaymentConnectedStripeCallback accountId={accountId} sessionId={stripeSessionId} />;
  } else if (isLoading) {
    content = <Skeleton variant="rounded" height={52} />;
  } else if (isError) {
    content = <ErrorBox retry={refetch} msg="There was an error retrieving your payment information." />;
  } else if (data) {
    content = paymentMethodProvided ? (
      <PaymentConnected accountId={accountId} paymentInformation={data} />
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <PaymentProviders accountId={accountId} />
        <Typography variant="body2" color="error.main">
          Your payment connection is not setup yet.
        </Typography>
      </Box>
    );
  }

  const isBilledByStripe = data?.type_ === 'stripe';
  const isBilledCustom = data?.type_ === 'custom';

  return (
    <Grid container={true} direction="column" spacing={4} wrap="nowrap">
      <Grid item={true}>
        <Card>
          <CardContent id={PAYMENT_INFO_SECTION_ELEMENT_ID}>
            <Grid container={true} spacing={2} direction="column" wrap="nowrap">
              <Grid
                item={true}
                role="region"
                aria-labelledby="payment-info-title"
                aria-describedby="payment-info-subtitle"
              >
                <Grid container={true} rowSpacing={2} direction="column">
                  <Grid item={true}>
                    <Typography variant="h6" id="payment-info-title">
                      Payment information
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5 }} id="payment-info-subtitle">
                      Payment connection setup.
                    </Typography>
                  </Grid>
                  <Grid item={true}>{content}</Grid>
                  {!isBilledCustom && (
                    <Grid item={true}>
                      <Typography variant="body2" color="text.secondary">
                        All sensitive data is stored on the provider side only.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {paymentMethodProvided && isBilledByStripe && (
                <>
                  <Grid item={true}>
                    <Alert severity="info">Please complete your billing information.</Alert>
                  </Grid>
                  <Grid item={true} sx={{ contain: 'content' }}>
                    <Grid container={true} columns={3} spacing={8}>
                      <Grid item={true} xs={1}>
                        <LazyPaymentInformationForm accountId={accountId} paymentInformation={data} />
                      </Grid>
                      {isBillingTaxSupportedCountry(data) && (
                        <Grid
                          item={true}
                          xs={1}
                          role="region"
                          aria-labelledby="tax-id-heading"
                          aria-describedby="tax-id-subheading"
                        >
                          <PaymentTaxIdForm paymentInformation={data} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {!props.paymentInfoOnly && (
        <>
          {isBilledByStripe && data.payment_method_id && (
            /*
            A payment info is created for Stripe when paymentInfo is requested and
            payment_method_id could be undefined if the user has not yet finished the payment setup with Stripe
            */
            <Grid item={true}>
              <Invoices accountId={accountId} />
            </Grid>
          )}
          <Grid item={true}>
            <LazyBillingCycles accountId={accountId} />
          </Grid>
        </>
      )}
    </Grid>
  );
});
