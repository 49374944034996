import { Delete } from '@mui/icons-material';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import { memo, useCallback, useState } from 'react';
import { useTrackInteractionEvent } from '../../../hooks/use-event-tracking';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useDeleteClusterByIdMutation } from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { Cluster } from '../../../utils/cluster-utils';
import { ConfirmationDialogDangerAction } from '../../Common/ConfirmationDialogDangerAction';

export const ClusterDeleteButton = memo(function ClusterDeleteButton({
  cluster,
  accountId,
  sx,
}: {
  cluster: Cluster;
  accountId: string;
  sx?: SxProps;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const matchRoute = useMatchRoute();
  const [clusterDeleteDialogOpen, setClusterDeleteDialogOpen] = useState(false);
  const [deleteCluster, { isLoading }] = useDeleteClusterByIdMutation();

  const navigate = useNavigate();

  const trackDeleteClick = useTrackInteractionEvent({
    location: 'Clusters Dashboard',
    label: `Delete: ${cluster.name}`,
    action: 'clicked',
  });
  const handleDeleteCluster = () => {
    trackDeleteClick();
    setClusterDeleteDialogOpen(true);
  };

  const trackPermanentDeleteClick = useTrackInteractionEvent({
    location: 'Clusters Dashboard',
    label: `Permanently Delete: ${cluster.name}`,
    action: 'clicked',
  });
  const onDeleteCluster = useCallback(async () => {
    if (isLoading) {
      return;
    }
    trackPermanentDeleteClick();
    const result = await deleteCluster({
      cluster_id: cluster.id,
      account_id: accountId,
      delete_backups: true,
    });
    if (isFetchMutationError(result)) {
      enqueueSnackbar('There was a problem deleting the cluster. Try again later.', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(`Cluster ${cluster.name} successfully deleted`, { variant: 'success' });
      // Only navigate to clusters when user is on the deleted cluster's detail page.
      if (matchRoute({ from: '/accounts/$accountId/clusters/$clusterId' })) {
        await navigate({
          to: '/accounts/$accountId/clusters',
          params: { accountId },
          replace: true,
        });
      }
    }
  }, [trackPermanentDeleteClick, accountId, isLoading, cluster, navigate, deleteCluster, enqueueSnackbar, matchRoute]);

  return (
    <>
      <Button
        sx={{ ...sx }}
        variant="outlined"
        color="error"
        endIcon={isLoading ? <CircularProgress size={20} color="error" /> : <Delete fontSize="small" />}
        onClick={() => {
          handleDeleteCluster();
        }}
        disabled={isLoading}
      >
        Delete
      </Button>

      <ConfirmationDialogDangerAction
        open={clusterDeleteDialogOpen}
        onClose={() => setClusterDeleteDialogOpen(false)}
        actionTarget={cluster.name}
        title="Deletion of your cluster"
        content="Are you sure you want to delete this cluster, this can't be reverted and will take
                    effect immediately"
        warning="This action cannot be undone"
        actionName="Delete permanently"
        actionHandler={onDeleteCluster}
      />
    </>
  );
});
