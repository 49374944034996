import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { Scrollbar } from '../Common/Scrollbar';
import { QdrantTable } from '../Common/QdrantTable';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { useCallback, useMemo, useState } from 'react';
import { SeverityPill } from '../Common/SeverityPill';
import { useTheme } from '@mui/material/styles';
import { EditAccountNameDialog } from './EditAccountNameDialog';
import {
  useChangeAccountNameMutation,
  useLeaveAccountMutation,
  useSetDefaultAccountMutation,
  useDeleteAccountMutation,
} from '../../services/iamApi';
import { getUserRoles } from '../../utils/user';
import { isFetchMutationError } from '../../services/helpers';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';
import { useAccountUser } from '../../routes/_account';

const ACCOUNTS_COLUMNS = [
  { title: 'Name', dataKey: 'name' },
  { title: 'Roles', dataKey: 'roles' },
  { title: 'Owner', dataKey: 'owner' },
  { title: 'ID', dataKey: 'id' },
  { title: '', dataKey: 'actions', align: 'left' },
];

export const UserAccounts = () => {
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();
  const [editingAccount, setEditingAccount] = useState(null);
  const { id: userId, accounts, default_account_id: defaultAccountId, email: userEmail } = useAccountUser();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [{ isLoading: changingAccountName }] = useChangeAccountNameMutation();
  const [setDefaultAccount, { isLoading: settingDefaultAccount }] = useSetDefaultAccountMutation();
  const [leaveAccount, { isLoading: leavingAccount }] = useLeaveAccountMutation();
  const [deleteAccount, { isLoading: deletingAccount }] = useDeleteAccountMutation();
  const performingAnAction = changingAccountName || settingDefaultAccount || leavingAccount || deletingAccount;

  const changeDefaultAccount = useCallback(
    async (accountId) => {
      if (changingAccountName) {
        return;
      }
      try {
        const response = setDefaultAccount({ accountId, userId });
        if (response.error) {
          enqueueSnackbar('There was a problem changing your default account. Try again later.', { variant: 'error' });
        } else {
          enqueueSnackbar('Your default account was changed successfuly.', { variant: 'success' });
        }
      } catch (e) {
        enqueueSnackbar('There was a problem changing your default account. Try again later.', { variant: 'error' });
      }
    },
    [setDefaultAccount, enqueueSnackbar, userId, changingAccountName],
  );

  const leaveAccountHandler = useCallback(
    async (accountId) => {
      try {
        const response = await leaveAccount({ accountId, userId });
        if (response.error) {
          enqueueSnackbar('There was a problem removing you from the account. Try again later.', { variant: 'error' });
        } else {
          enqueueSnackbar('You were successfully removed from the account.', { variant: 'success' });
        }
      } catch (e) {
        enqueueSnackbar('There was a problem removing you from the account. Try again later.', { variant: 'error' });
      }
    },
    [enqueueSnackbar, leaveAccount, userId],
  );

  const deleteAccountHandler = useCallback(
    async (accountId) => {
      const result = await deleteAccount({ account_id: accountId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem deleting the account. Try again later.', { variant: 'error' });
      }
    },
    [deleteAccount, enqueueSnackbar],
  );

  const accountRows = useMemo(() => {
    return accounts.map((account) => {
      const { id, name, owner_email: owner, user_roles: roles } = account;
      const rowData = { id, name, owner, roles };
      return {
        name: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {name}
            {id === defaultAccountId && (
              <SeverityPill color="inactive" small={true}>
                Default
              </SeverityPill>
            )}
            <Button variant="text" size="small" onClick={() => setEditingAccount(account)}>
              Edit name
            </Button>
          </Box>
        ),
        owner,
        roles: getUserRoles({ isAccountOwner: owner === userEmail, theme, roles }),
        rowData,
        id: <CopyToClipboardText text={id} sxTextField={{ flexGrow: 2 }} />,
      };
    });
  }, [accounts, defaultAccountId, userEmail, theme]);

  const accountActions = useMemo(
    () => [
      {
        name: 'Make default',
        getHandler:
          ({ name, id }) =>
          () =>
            showConfirmation({
              actionName: 'Confirm',
              title: `Set new default account`,
              content: `Do you want to set '${name}' as your default account?`,
              actionHandler: () => changeDefaultAccount(id),
            }),
        isDisabled: ({ id }) => defaultAccountId === id || performingAnAction,
      },
      {
        name: 'Leave account',
        getHandler:
          ({ name, id }) =>
          () =>
            showConfirmation({
              actionName: 'Confirm',
              title: `Leave account`,
              content: `Do you want to leave '${name}'?`,
              warning: 'Once done, you will not be able to access the account again, unless you are invited again.',
              actionHandler: () => leaveAccountHandler(id),
            }),
        isDisabled: ({ owner }) => owner === userEmail || performingAnAction,
      },
      {
        name: 'Edit name',
        getHandler: (account) => () => setEditingAccount(account),
        isDisabled: (account) => performingAnAction,
      },
      {
        name: 'Delete account',
        getHandler: (account) => () =>
          showConfirmation({
            actionName: 'Confirm',
            setConfirmAction: true,
            extraConfirmation:
              'I understand that this will permanently delete my account and all associated data from Qdrant.',
            extraConfirmationRequired: true,
            title: `Delete account`,
            content: `Are you sure you want to delete the account "${account.name}"?`,
            warning:
              'Please note, once deleted, you will not be able to access this account again with the associated email.',
            actionHandler: (extraConfirmationChecked) => {
              if (extraConfirmationChecked) {
                deleteAccountHandler(account.id);
              }
            },
          }),
        isDisabled: ({ owner }) => owner !== userEmail || performingAnAction,
      },
    ],
    [
      changeDefaultAccount,
      deleteAccountHandler,
      leaveAccountHandler,
      showConfirmation,
      performingAnAction,
      defaultAccountId,
      userEmail,
    ],
  );

  return (
    <>
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Typography variant="h6">Accounts</Typography>
        </CardContent>
        <Scrollbar>
          <Box data-sentry-mask={true}>
            <QdrantTable
              columns={ACCOUNTS_COLUMNS}
              rows={accountRows}
              rowActions={accountActions}
              pagination={true}
              ariaLabel="User accounts"
            />
          </Box>
        </Scrollbar>
      </Card>
      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
      {editingAccount && (
        <EditAccountNameDialog
          accountId={editingAccount.id}
          value={editingAccount.name}
          onClose={() => setEditingAccount(null)}
        />
      )}
    </>
  );
};
