import { ComponentSelector } from '@emotion/styled';
import { styled, Box, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { ClusterTemplateType } from './ClusterTemplateList';
import { useAuth } from '../../../router/hooks';
import { CloudProvider, CloudRegion } from '../../../services/clustersApi';
import { typedMemo } from '../../../utils/func-utils';
import { hasDeveloperRole } from '../../../utils/user-utils';
import { ButtonLike } from '../../Common/ButtonLike';
import {
  CLOUD_PROVIDER_CONFIG,
  CLOUD_PROVIDER_GEOGRAPHY_MAP,
  CLOUD_PROVIDER_MAP,
  FREE_TIER_CLOUD_PROVIDER,
  ProviderRegion,
} from '../constants';

const GeographyTypographyStyled = styled('div')(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    background: ${theme.palette.background.code};
    font-size: ${theme.typography.body2.fontSize!};
    line-height: ${theme.typography.body2.lineHeight!};
    font-weight: ${theme.typography.fontWeightMedium!};
    border-radius: 4px;
    display: inline-block;
    padding: 0 ${theme.spacing(2)};
`,
);

const ClusterRegionListStyled = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const RegionWrapperStyled = styled(Box)`
  display: flex;
`;

const ItemButton = styled(ButtonLike)(
  ({ theme }) => `
    margin-top: 12px;
    padding: 4px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: ${theme.palette.divider};
    
    &:hover,
    &:focus-visible {
      background-color: ${theme.palette.action.hover};
    }

    &[aria-pressed='true'] {
      background-color: ${theme.palette.background.paper};
      border-color: ${theme.palette.primary.light};
    }
  `,
) as typeof ButtonLike & ComponentSelector;

export function getDefaultRegion(provider: CloudProvider, preSelectedRegion?: CloudRegion): CloudRegion {
  if (provider === CLOUD_PROVIDER_MAP.PRIVATE) {
    /*
     * TODO: once the old create cluster dialog is removed, the `defaultRegion` value
     * can be changed from '' to 'private' and this can be removed as well.
     */
    return CLOUD_PROVIDER_MAP.PRIVATE;
  }
  const appEnv = window.__QDRANT_CLOUD__.env === 'production' ? 'production' : 'staging';
  const provider_config = CLOUD_PROVIDER_CONFIG.providers[provider][appEnv];
  // If there was a pre-selected region, check if it belongs to the selected provider.
  if (preSelectedRegion && provider_config.availableRegions.some(({ value }) => preSelectedRegion === value)) {
    return preSelectedRegion;
  }
  return provider_config.defaultRegion;
}

export const ClusterRegionList = typedMemo(function ClusterRegionList<TRegion extends CloudRegion>({
  provider,
  region,
  onChange,
  clusterTemplate,
  ariaLabelledBy,
}: {
  provider: keyof typeof CLOUD_PROVIDER_GEOGRAPHY_MAP;
  region: TRegion;
  onChange: (value: TRegion) => void;
  clusterTemplate?: ClusterTemplateType;
  ariaLabelledBy?: string;
}) {
  const { user } = useAuth();
  const clickHandler = useCallback(
    (data: TRegion) => {
      if (data !== region) {
        onChange(data);
      }
    },
    [onChange, region],
  );

  return (
    <ClusterRegionListStyled aria-labelledby={ariaLabelledBy}>
      {Object.entries(CLOUD_PROVIDER_GEOGRAPHY_MAP[provider])
        .filter(
          // For free tiers only allow default region
          ([geography]) =>
            clusterTemplate === undefined ||
            clusterTemplate !== 'free' ||
            (provider === FREE_TIER_CLOUD_PROVIDER && geography === 'Europe'),
        )
        .map(([geography, providerRegions]: [string, ProviderRegion[]]) => (
          <Box key={geography}>
            <GeographyTypographyStyled>{geography}</GeographyTypographyStyled>
            {providerRegions.map(({ flag, name, value, devOnly }) =>
              !devOnly || hasDeveloperRole(user) ? (
                <Tooltip key={name} title={value} placement="bottom" arrow={true}>
                  <RegionWrapperStyled>
                    <ItemButton
                      onClick={clickHandler}
                      pressed={value === region ? true : undefined}
                      value={value as TRegion}
                    >
                      {flag} {name}
                    </ItemButton>
                  </RegionWrapperStyled>
                </Tooltip>
              ) : null,
            )}
          </Box>
        ))}
    </ClusterRegionListStyled>
  );
});
