import TerminalIcon from '@mui/icons-material/Terminal';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { ClusterQueryCodeExample } from './ClusterQueryCodeExample';
import { Cluster } from '../../services/clustersApi';
import { accessDatabaseListItems } from '../Overview/WelcomeStepper/Horizontal/GetStartedStep';

export function ClusterDatabaseAccess({ cluster, dashboardURL }: { cluster: Cluster; dashboardURL?: string | null }) {
  return (
    <Grid
      container={true}
      spacing={3}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        mb: 6,
      }}
    >
      {dashboardURL && (
        <Grid item={true} xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            sx={{
              px: 5,
              py: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <CardHeader
              sx={{ px: 0, pt: 3, pb: 2, '& span': { fontSize: '1.25em' } }}
              title="Access the Database Dashboard"
            />
            <CardContent sx={{ px: 0, py: 0, flexGrow: 1 }}>
              <List
                sx={{
                  p: 0,
                  '& li': {
                    p: 0,
                    mb: 4,
                    '& img': { height: 36, mr: 2 },
                    '& div': { m: 0, '& span': { fontSize: '0.875em' } },
                  },
                }}
              >
                {accessDatabaseListItems.map((item, index) => (
                  <ListItem key={index}>
                    <Box component="img" src={item.img} alt={item.altText} />
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
              <Button
                onClick={() => window.open(dashboardURL, '_blank')}
                type="button"
                variant="outlined"
                endIcon={<TerminalIcon fontSize="small" />}
                sx={{ m: 1 }}
              >
                Open Dashboard
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item={true} xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Card
          sx={{
            px: 5,
            py: 4,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <CardHeader sx={{ px: 0, pt: 3, pb: 2, '& span': { fontSize: '1.25em' } }} title="Use the API" />
          <CardContent sx={{ px: 0, py: 0, flexGrow: 1 }}>
            <Typography color="textSecondary" component={'p'} sx={{ fontSize: '14px' }}>
              Use this code snippet to access your cluster via API:
            </Typography>
            <ClusterQueryCodeExample clusters={[cluster]} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
