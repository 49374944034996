import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { createClusterNameSchema } from './validation-schemas';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useRenameClusterMutation, useGetClustersByAccountIdQuery } from '../../services/clustersApi';

type RenameClusterModalProps = {
  open: boolean;
  onClose: () => void;
  currentName: string;
  accountId: string;
  clusterId: string;
};

export function RenameClusterModal({ open, onClose, currentName, accountId, clusterId }: RenameClusterModalProps) {
  const { data: existingClusters = [], isFetching } = useGetClustersByAccountIdQuery({ accountId });
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: currentName },
    resolver: zodResolver(createClusterNameSchema(existingClusters)),
    disabled: isFetching,
    mode: 'all',
  });

  const [renameCluster, { isLoading }] = useRenameClusterMutation();

  const onSubmit = async (data: { name: string }) => {
    try {
      await renameCluster({ account_id: accountId, cluster_id: clusterId, name: data.name }).unwrap();
      enqueueSnackbar('Cluster renamed successfully', { variant: 'success' });
      onClose();
    } catch {
      enqueueSnackbar('Failed to rename cluster', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rename Cluster</DialogTitle>
      <DialogContent sx={{ minWidth: 400, minHeight: 130 }}>
        <TextField
          {...register('name')}
          autoFocus={true}
          margin="dense"
          label="Cluster Name"
          type="text"
          fullWidth={true}
          variant="standard"
          disabled={isLoading}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={isLoading} variant="contained">
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
