import { List, ListSubheader, SxProps } from '@mui/material';
import { ReactElement } from 'react';
import { DashboardSidebarItem } from './DashboardSidebarItem';

export type SectionItem = {
  key?: string;
  title: string;
  href: string;
  icon: ReactElement;
};

type NavItemsProps = {
  items: SectionItem[];
  depth?: number;
};

type AuthenticatedDashboardSidebarSectionProps = {
  items: SectionItem[];
  title: string;
  sx: SxProps;
};

const NavItems = ({ items }: NavItemsProps) => (
  <List role="menu" disablePadding={true}>
    {items.reduce<ReactElement<typeof DashboardSidebarItem>[]>((acc, item) => reduceChildRoutes({ acc, item }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, item }: { acc: ReactElement<typeof DashboardSidebarItem>[]; item: SectionItem }) => {
  acc.push(<DashboardSidebarItem key={item.title} icon={item.icon} title={item.title} href={item.href} />);

  return acc;
};

export const AuthenticatedDashboardSidebarSection = ({
  items,
  title,
  sx,
}: AuthenticatedDashboardSidebarSectionProps) => (
  <List
    subheader={
      <ListSubheader
        disableGutters={true}
        disableSticky={true}
        sx={{
          color: 'neutral.500',
          fontSize: '0.75rem',
          fontWeight: 700,
          lineHeight: 2.5,
          ml: 4,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </ListSubheader>
    }
    sx={sx}
  >
    <NavItems items={items} />
  </List>
);
