import { SxProps, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import { TableRowActions } from './TableRowActions';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = 'desc' | 'asc';
export type RowAction<T> = {
  name: string;
  getHandler: (rowData: T) => () => void;
  isDisabled?: boolean | ((rowData: T) => boolean);
  icon?: ReactElement;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowType<T> = Record<string, any> & { rowData: T };
export type ColumnType<T> = {
  title: string;
  dataKey: Exclude<keyof RowType<T>, 'rowData'>;
  align?: 'center' | 'left' | 'right';
  hidden?: boolean;
};

export type QdrantTableProps<T> = {
  columns: ColumnType<T>[];
  rows: RowType<T>[];
  ariaLabel: string;
  order?: [keyof T, Order];
  pagination?: boolean;
  rowActions?: RowAction<T>[];
  sx?: SxProps;
};

function QdrantTable<T>({ columns, rows, ariaLabel, sx, rowActions, pagination, order }: QdrantTableProps<T>) {
  const visibleColumns = columns.filter(({ hidden }) => !hidden);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const orderedRows = useMemo(() => {
    if (!order) {
      return rows;
    }

    const orderedRows = [...rows];
    const isDesc = order[1] === 'desc';
    const orderBy = order[0];
    return orderedRows.sort((a, b) => (isDesc ? 1 : -1) * descendingComparator(a.rowData, b.rowData, orderBy));
  }, [rows, order]);

  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Table sx={sx} aria-label={ariaLabel}>
        <TableHead>
          <TableRow>
            {visibleColumns.map(({ title, align, dataKey }) => (
              <TableCell key={String(dataKey)} align={align} component="th">
                {title}
              </TableCell>
            ))}
            {rowActions && (
              <TableCell align="right" component="th">
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} key={index}>
              {visibleColumns.map(({ dataKey, align }) => (
                <TableCell key={String(dataKey)} align={align}>
                  {row[dataKey]}
                </TableCell>
              ))}
              {rowActions && (
                <TableCell align="right">
                  <TableRowActions actions={rowActions} data={row.rowData} ariaLabel="more actions" />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          role="navigation"
          aria-label={`${ariaLabel} pagination`}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            setPage(0);
            setRowsPerPage(parseInt(e.target.value));
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}

QdrantTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      align: PropTypes.string,
      hidden: PropTypes.bool,
    }).isRequired,
  ),
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      getHandler: PropTypes.func.isRequired,
      isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    }),
  ),
  pagination: PropTypes.bool,
  sx: PropTypes.object,
  order: PropTypes.array,
};

export { QdrantTable };
