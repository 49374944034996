import MonthIcon from '@mui/icons-material/CalendarMonth';
import HourIcon from '@mui/icons-material/QueryBuilder';
import { Box, Button, Link, ListItem, SxProps, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Quantization } from './utils';
import { useTrackInteractionEvent } from '../../hooks/use-event-tracking';
import { Link as RouterLink } from '../../router/Link';
import { CloudProvider, CloudRegion } from '../../services/clustersApi';
import { BookingPackageOutput } from '../../utils/booking-utils';
import { TIMES_UNICODE_SYMBOL } from '../../utils/constants';
// eslint-disable-next-line max-len
import { ClusterDeploymentSummaryConfigurationList } from '../Clusters/ClusterSetup/ClusterDeploymentSummary/ClusterDeploymentSummaryConfigurationList';
import { CloudProviderIcon } from '../Clusters/ClusterSetup/ClusterProviderList/CloudProviderIcon';
import {
  PricedEntityPrices,
  PricedEntityRates,
  getPricedEntityPrices,
  getPricedEntityRates,
  toPackageResourcesObject,
} from '../Clusters/ClusterSetup/helpers';
import { StyledListItemIcon, StyledListItemText } from '../Clusters/ClusterSetup/styled-components';
import { AVG_DAYS_PER_MONTH, HOURS_IN_A_DAY } from '../Clusters/constants';

export const CONTACT_FORM_URL = 'https://share-eu1.hsforms.com/1D-3DWhKtR06G0Cyzvy0jPA2b46ng';
const MAX_PRICE_DECIMALS = 5;
const ClusterDeploymentSummaryHourlyPrice = ({
  rates,
  prices,
  nodes,
}: {
  rates: NonNullable<PricedEntityRates>;
  prices: NonNullable<PricedEntityPrices>;
  nodes: number;
}) => (
  <>
    {[
      rates.hourlyItem.toFixed(MAX_PRICE_DECIMALS),
      TIMES_UNICODE_SYMBOL,
      nodes,
      `node${nodes > 1 ? 's' : ''}  = `,
    ].join(' ')}
    <Typography variant="h6" component="u">
      {prices.hourlyTotal}/hour
    </Typography>
  </>
);

const ClusterDeploymentSummaryMonthlyPrice = ({
  rates,
  prices,
  nodes,
}: {
  rates: NonNullable<PricedEntityRates>;
  prices: NonNullable<PricedEntityPrices>;
  nodes: number;
}) => (
  <>
    {[
      rates.hourlyItem.toFixed(MAX_PRICE_DECIMALS),
      TIMES_UNICODE_SYMBOL,
      HOURS_IN_A_DAY,
      'hours',
      TIMES_UNICODE_SYMBOL,
      AVG_DAYS_PER_MONTH,
      'days',
      TIMES_UNICODE_SYMBOL,
      nodes,
      `node${nodes > 1 ? 's' : ''} = `,
    ].join(' ')}
    <Typography variant="h6" component="u" sx={{ ml: 1 }}>
      {prices.monthlyTotal}/month
    </Typography>
    *
  </>
);

const PriceSummary = ({ recommendedPackage, nodes }: { recommendedPackage?: BookingPackageOutput; nodes: number }) => {
  if (!recommendedPackage) {
    return null;
  }
  const packageRates = getPricedEntityRates({ entity: recommendedPackage, multiplier: nodes });
  if (!packageRates) {
    return null;
  }
  const packagePrices = getPricedEntityPrices({ pricedEntityRates: packageRates });
  if (!packagePrices) {
    return null;
  }

  // return <HourlyAndMonthlyPriceSummary rates={packageRates} prices={packagePrices} nodes={nodes} />;
  return (
    <>
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <HourIcon fontSize="medium" role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          sx={{ alignItems: 'center' }}
          primary="Hourly:"
          primaryTypographyProps={{ variant: 'h6' }}
          secondaryTypographyProps={{ variant: 'body1', color: 'text.primary' }}
          secondary={<ClusterDeploymentSummaryHourlyPrice rates={packageRates} prices={packagePrices} nodes={nodes} />}
        />
      </ListItem>
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <MonthIcon fontSize="medium" role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          sx={{ alignItems: 'center' }}
          primary="Monthly:"
          primaryTypographyProps={{ variant: 'h6' }}
          secondaryTypographyProps={{ variant: 'body1', color: 'text.primary' }}
          secondary={<ClusterDeploymentSummaryMonthlyPrice rates={packageRates} prices={packagePrices} nodes={nodes} />}
        />
      </ListItem>
    </>
  );
};

const ResultWrapper = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => (
  <Box
    role="region"
    aria-label="Calculator Result"
    sx={{
      md: { mt: 5 },
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const CalculatorResult = ({
  recommendedPackage,
  replicas,
  nodes,
  requiredMemory,
  selectedProvider,
  selectedRegion,
  quantization,
}: {
  recommendedPackage?: BookingPackageOutput;
  replicas: number;
  nodes: number;
  requiredMemory?: number;
  selectedProvider?: CloudProvider;
  selectedRegion?: CloudRegion;
  quantization: Quantization;
}) => {
  const trackClick = useTrackInteractionEvent({
    label: 'Get Started',
    location: 'Calculator',
    action: 'clicked',
  });

  const onGetStartedClick = useCallback(() => {
    trackClick();
  }, [trackClick]);

  const packageResources = useMemo(
    () => (!recommendedPackage ? undefined : toPackageResourcesObject(recommendedPackage).getClusterConfiguration()),
    [recommendedPackage],
  );

  if (recommendedPackage === undefined) {
    return (
      <ResultWrapper sx={{ mt: 10 }}>
        <Typography variant="body1" color="textSecondary">
          Fill out the form to get your personalized recommendations.
        </Typography>
      </ResultWrapper>
    );
  }

  return (
    <ResultWrapper sx={{ mt: 20 }}>
      {packageResources && selectedProvider && (
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Example configuration:
          </Typography>
          <Typography sx={{ mb: 2 }} id="required-ram">
            Estimated RAM per replica: {requiredMemory} GB
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <CloudProviderIcon provider={selectedProvider} />
            {selectedProvider !== 'private' && <span>({selectedRegion})</span>}
          </Box>
          <ClusterDeploymentSummaryConfigurationList
            memory={packageResources.memory}
            cpu={packageResources.cpu}
            nodes={replicas * nodes}
            disk={packageResources.disk}
            mediumSize={true}
            quantization={quantization}
            // Added for completion but not used
            complimentaryDisk={packageResources.complimentaryDisk}
            additionalDisk={packageResources.additionalDisk}
          />
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Price
          </Typography>
          <PriceSummary recommendedPackage={recommendedPackage} nodes={replicas * nodes} />

          <Typography variant="body2" color="text.secondary">
            * Approximated average price. VAT excluded. This shall not be deemed to constitute a binding offer made by
            Qdrant.
          </Typography>
          <Box sx={{ mt: 5, display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              color="secondary"
              variant="contained"
              sx={{ width: 'fit-content', flexShrink: 0 }}
              onClick={onGetStartedClick}
              component={RouterLink}
              to="/"
            >
              Get Started
            </Button>
            <Typography variant="h6" color="text.primary">
              <span>Do you have further pricing questions? </span>
              <Link href={CONTACT_FORM_URL} rel="noreferrer" target="_blank">
                Contact us
              </Link>
              <span>.</span>
            </Typography>
          </Box>
        </Box>
      )}
    </ResultWrapper>
  );
};
