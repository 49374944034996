import { Box, CardContent, CardHeader, Typography, FormControl, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { CodeSnippetLang, getCodeSnippet, CODE_SNIPPET_LANGS } from './clusterCodeSnippets';
import { Cluster, findClusterById } from '../../utils/cluster-utils';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';
import { CloudLink } from '../Navigation/CloudLink';

export function ClusterQueryCodeExample({ clusters, token }: { clusters?: Cluster[]; token?: string }) {
  const getHostFromUrl = (cluster?: Cluster) => {
    if (cluster?.state?.endpoint) {
      return new URL(cluster.state.endpoint).hostname;
    }

    return 'example.eu-central.aws.cloud.qdrant.com';
  };

  const [selectedClusterId, setSelectedClusterId] = useState(clusters?.length ? clusters[0].id : undefined);
  const selectedCluster = findClusterById(selectedClusterId, clusters);
  const hostPlaceholder = getHostFromUrl(selectedCluster);

  if (!token) {
    token = '<your-token>';
  }

  const snippetsTypesList = function () {
    return Object.keys(CODE_SNIPPET_LANGS).map((key) => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  const [codeLang, setCodeLang] = useState<CodeSnippetLang>('curl');
  const codeSnippet = getCodeSnippet(hostPlaceholder, token, codeLang);

  return (
    <>
      <CardHeader color="primary" title={'Usage Examples'}></CardHeader>
      <CardContent sx={{ pt: 0 }}>
        {!clusters?.length && (
          <Box>
            <Typography variant={'body2'}>
              This code snippet is just an example because you do not have a cluster yet. You can create a cluster{' '}
              <CloudLink to={'/clusters'}>here</CloudLink>
            </Typography>
          </Box>
        )}
        <Box>
          <Box display={'flex'}>
            <Box>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select<CodeSnippetLang>
                  labelId="code-select"
                  id="code-select"
                  value={codeLang}
                  onChange={(event) => {
                    setCodeLang(event.target.value as CodeSnippetLang);
                  }}
                >
                  {snippetsTypesList()}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select<string>
                  labelId="cluster-select"
                  id="cluster-select"
                  value={selectedClusterId}
                  disabled={clusters?.length === 1}
                  onChange={(event) => {
                    setSelectedClusterId(event.target.value);
                  }}
                >
                  {clusters?.map((cluster) => (
                    <MenuItem value={cluster.id} key={cluster.id}>
                      {cluster.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flexGrow={1}></Box>
          </Box>
          <Box
            sx={(theme) => ({ borderRadius: 1, position: 'relative', backgroundColor: theme.palette.background.code })}
          >
            <Box m={1} data-sentry-mask={true}>
              <CopyToClipboardText text={codeSnippet} useCodeStyle={true} codeLanguage={codeLang} />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
