import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

const BORDER_RADIUS = '10px';
const AVAILABLE_COLOR = 'rgb(167, 202, 237)';
const AVAILABLE_KEY = 'available';
const hoveredValueStyles: SxProps = { fontWeight: 'bold' };

type StackedMetrics = { key: string; value: number; tooltip: string; color: string };
type Props = {
  metrics: StackedMetrics[];
  total: number;
  format: (value: number) => string;
  sx?: SxProps<Theme>;
};

const TooltipMap = (props: Props & { available: number; hovered?: string }) => {
  const isAvailableHovered = props.hovered === AVAILABLE_KEY;
  const AvailableIcon = isAvailableHovered ? RadioButtonIcon : CircleIcon;
  const filteredMetrics = props.metrics.filter(({ value }) => value);
  return (
    <Box>
      {filteredMetrics.map(({ key, value, color, tooltip }) => {
        const isHovered = key === props.hovered;
        const Icon = isHovered ? RadioButtonIcon : CircleIcon;
        return (
          <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon sx={{ color }} fontSize="small" />
            <Typography sx={isHovered ? hoveredValueStyles : undefined}>
              {tooltip}: {props.format(value)}
            </Typography>
          </Box>
        );
      })}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <AvailableIcon sx={{ color: AVAILABLE_COLOR }} fontSize="small" />
        <Typography sx={isAvailableHovered ? hoveredValueStyles : undefined}>
          Available: {props.format(props.available)}
        </Typography>
      </Box>
    </Box>
  );
};

export const StackedLinearMetric = (props: Props) => {
  const { total, metrics } = props;
  const [hovered, setHovered] = useState<string | undefined>(undefined);
  const filteredMetrics = metrics.filter(({ value }) => value);
  const taken = filteredMetrics.reduce((acc, { value }) => acc + value, 0);
  let available = total - taken;
  // Available should never be negative, but we still check for it.
  if (available < 0) {
    available = 0;
  }

  return (
    <Tooltip title={<TooltipMap {...props} hovered={hovered} available={available} />}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          wrap: 'nowrap',
          maxWidth: '100%',
          width: '100%',
          height: 12,
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
          backgroundColor: 'rgb(167, 202, 237, 1)',
          '&:hover': {
            backgroundColor: 'rgb(167, 202, 237, .5)',
          },
          mt: 1,
          cursor: 'pointer',
        }}
      >
        {filteredMetrics.map(({ key, value, color }) => (
          <Box
            key={key}
            sx={{
              width: `${(value / total) * 100}%`,
              backgroundColor: color,
              overflow: 'hidden',
              '&:hover': {
                opacity: 0.5,
              },
            }}
            onMouseOver={() => setHovered(key)}
            onMouseOut={() => setHovered(undefined)}
          />
        ))}
        <Box
          sx={{
            width: `${(available / total) * 100}%`,
            backgroundColor: 'rgb(167, 202, 237, 1)',
            '&:hover': {
              backgroundColor: 'rgb(167, 202, 237, .5)',
            },
            borderTopRightRadius: BORDER_RADIUS,
            borderBottomRightRadius: BORDER_RADIUS,
            overflow: 'hidden',
          }}
          onMouseOver={() => setHovered(AVAILABLE_KEY)}
          onMouseOut={() => setHovered(undefined)}
        />
      </Box>
    </Tooltip>
  );
};
