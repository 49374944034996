import CheckIcon from '@mui/icons-material/Check';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { memo } from 'react';

export const ClusterDeploymentSummaryFeatureItems = memo(function ClusterDeploymentSummaryFeatureItems({
  isFreeTemplate,
}: {
  isFreeTemplate: boolean;
}) {
  return isFreeTemplate ? (
    <>
      <ListItem disablePadding={true}>
        <ListItemText primary="Standard support" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemText primary="Automatic suspension after 1 week of inactivity" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemText primary="Automatic deletion after 4 weeks of inactivity" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemText primary="Only manual snapshots and restores via API" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemText primary="No dedicated resources" />
      </ListItem>
    </>
  ) : (
    <>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="Dedicated resources" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="Backup and disaster recovery" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="Horizontal and vertical scaling" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="Monitoring, and log management" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="Standard 10x5 enterprise support plan" />
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemIcon>
          <CheckIcon color="success" fontSize="small" role="presentation" />
        </ListItemIcon>
        <ListItemText primary="99.5% uptime SLA" />
      </ListItem>
    </>
  );
});
