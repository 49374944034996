const dateFormatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export function toReadableDate(isoDate: string) {
  return dateFormatter.format(new Date(isoDate));
}

export function toReadableDateTime(isoDate: string) {
  return dateTimeFormatter.format(new Date(isoDate));
}

export function toISOString(isoDate: string) {
  return new Date(isoDate).toISOString();
}
