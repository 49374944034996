import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardHeader, Skeleton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { BackupScheduleTable } from './BackupScheduleTable';
import { BackupScheduleForm, BackupScheduleFormDialog } from './BackupSchedulesForm/BackupScheduleForm';
import { useAccountId } from '../../../routes/_account';
import { useGetClusterBackupSchedulesQuery } from '../../../services/clustersApi';
import { BackupSchedule, Cluster } from '../../../utils/cluster-utils';
import { CardTitleWithLoadingIndicator } from '../../Common/CardTitleWithLoadingIndicator';
import { Scrollbar } from '../../Common/Scrollbar';

type SchedulesProps = {
  defaultCluster?: Cluster;
  loadingClusters?: boolean;
  availableClusters?: Cluster[];
};

type FormContainerProps = SchedulesProps & {
  accountId: string;
  noAvailableClustersForBackup: boolean;
};
const FormContainer = ({
  noAvailableClustersForBackup,
  accountId,
  defaultCluster,
  availableClusters,
}: FormContainerProps) => (
  <Box sx={{ px: 3, pb: 3 }}>
    {noAvailableClustersForBackup ? (
      <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
        There are no clusters eligible for backups.
      </Typography>
    ) : (
      <BackupScheduleForm accountId={accountId} defaultCluster={defaultCluster} availableClusters={availableClusters} />
    )}
  </Box>
);

type SchedulesBodyProps = FormContainerProps & {
  backupSchedules?: BackupSchedule[];
  editSchedule: (schedule: BackupSchedule) => void;
};
const SchedulesBody = ({
  backupSchedules,
  accountId,
  defaultCluster,
  availableClusters,
  noAvailableClustersForBackup,
  editSchedule,
}: SchedulesBodyProps) => {
  if (!backupSchedules?.length) {
    return (
      <FormContainer
        accountId={accountId}
        defaultCluster={defaultCluster}
        availableClusters={availableClusters}
        noAvailableClustersForBackup={noAvailableClustersForBackup}
      />
    );
  }
  return (
    <BackupScheduleTable
      accountId={accountId}
      defaultCluster={defaultCluster}
      availableClusters={availableClusters}
      backupSchedules={backupSchedules}
      onEditClick={editSchedule}
    />
  );
};

export const BackupSchedules = ({ defaultCluster, loadingClusters, availableClusters }: SchedulesProps) => {
  const [openScheduleFormDialog, setOpenScheduleFormDialog] = useState(false);
  const [scheduleObjToEdit, setScheduleObjToEdit] = useState<BackupSchedule | null>(null);
  const accountId = useAccountId();
  const {
    data: backupSchedules,
    isLoading,
    isFetching,
  } = useGetClusterBackupSchedulesQuery({ account_id: accountId, cluster_id: defaultCluster?.id });

  const noAvailableClustersForBackup = !defaultCluster && !loadingClusters && !availableClusters?.length;
  const loadingSchedules = isLoading || (!defaultCluster && loadingClusters);

  const editSchedule = useCallback((schedule: BackupSchedule) => {
    setScheduleObjToEdit(schedule);
    setOpenScheduleFormDialog(true);
  }, []);
  const hasBackupSchedules = backupSchedules && backupSchedules.length > 0;

  return (
    <Card
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',
      }}
    >
      <CardHeader
        title={<CardTitleWithLoadingIndicator title={'Schedules'} isLoading={isFetching} />}
        action={
          !isLoading &&
          hasBackupSchedules && (
            <Button
              variant="contained"
              aria-label="settings"
              startIcon={<AddIcon fontSize="small" />}
              onClick={() => setOpenScheduleFormDialog(true)}
              disabled={noAvailableClustersForBackup || isFetching || (!defaultCluster && loadingClusters)}
            >
              Add Schedule
            </Button>
          )
        }
      />
      <Scrollbar>
        {loadingSchedules ? (
          <Skeleton variant="rectangular" height={180} />
        ) : (
          <>
            <SchedulesBody
              accountId={accountId}
              noAvailableClustersForBackup={noAvailableClustersForBackup}
              availableClusters={availableClusters}
              backupSchedules={backupSchedules}
              defaultCluster={defaultCluster}
              editSchedule={editSchedule}
            />

            <BackupScheduleFormDialog
              schedule={scheduleObjToEdit}
              open={openScheduleFormDialog}
              onClose={() => {
                setScheduleObjToEdit(null);
                setOpenScheduleFormDialog(false);
              }}
              defaultCluster={defaultCluster}
              availableClusters={availableClusters}
              accountId={accountId}
            />
          </>
        )}
      </Scrollbar>
    </Card>
  );
};
