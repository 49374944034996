import CheckIcon from '@mui/icons-material/Check';
import GroupIcon from '@mui/icons-material/Group';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, ListItemIcon, MenuItem, Popover, Typography, styled } from '@mui/material';
import { forwardRef, useCallback, useRef, useState, useMemo } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { Link } from '../../router/Link';
import { useAccountUser } from '../../routes/_account';
import { Account } from '../../services/iamApi';

type OrganizationPopoverProps = {
  open: boolean;
  accounts: Account[];
  currentAccountId: string;
  onAccountChange: () => void;
  onClose: () => void;
};

const OrganizationPopover = forwardRef<Element, OrganizationPopoverProps>(function OrganizationPopover(
  { open, accounts, currentAccountId, onAccountChange, onClose },
  ref,
) {
  const sortedAccounts = useMemo(
    () => Array.from(accounts).sort((account) => (account.id === currentAccountId ? -1 : 1)),
    [accounts, currentAccountId],
  );
  return (
    <Popover
      anchorEl={ref && 'current' in ref ? ref.current : undefined}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted={true}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: 'fit-content' } } }}
      transitionDuration={0}
    >
      <Box>
        <Typography sx={{ py: 1, px: 2, fontWeight: 'bold' }}>Accounts</Typography>
      </Box>
      {sortedAccounts.map(({ id, name }) => {
        const isMatch = id === currentAccountId;
        return (
          <MenuItem
            key={id}
            component={Link}
            params={{ accountId: id }}
            onClick={isMatch ? undefined : onAccountChange}
            disabled={isMatch}
            selected={isMatch}
            sx={{ '&.Mui-disabled': { opacity: 1 } }}
          >
            {isMatch && (
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
            )}
            {name}
          </MenuItem>
        );
      })}
    </Popover>
  );
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  maxWidth: '215px',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.text.primary,
}));

export const AccountSelector = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const accountsRef = useRef(null);
  const { account, accounts } = useAccountUser();
  const { id: accountId } = account;

  const handlePopoverToggle = useCallback(() => {
    setAccountPopoverOpen((state) => !state);
  }, []);

  const handleAccountChange = useCallback(() => {
    setAccountPopoverOpen(false);
    enqueueSnackbar('Account changed', { variant: 'success' });
  }, [enqueueSnackbar]);

  return (
    <>
      <Box display="flex" gap={1} alignItems="baseline">
        <Box
          onClick={handlePopoverToggle}
          ref={accountsRef}
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            py: 1,
            borderRadius: 1,
          }}
        >
          <GroupIcon sx={{ mr: 1, color: 'text.primary' }} />
          <StyledTypography data-sentry-mask={true}>{account.name || '-'}</StyledTypography>
          <UnfoldMoreIcon
            sx={{
              color: 'neutral.500',
              width: 14,
              height: 14,
              // Prevent padding from pushing the icon too close to the name
              right: '-10px',
              position: 'relative',
            }}
          />
        </Box>
      </Box>
      {accounts && (
        <OrganizationPopover
          ref={accountsRef}
          accounts={accounts}
          open={accountPopoverOpen}
          onClose={handlePopoverToggle}
          currentAccountId={accountId}
          onAccountChange={handleAccountChange}
        />
      )}
    </>
  );
};
