import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as AccountRoute } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { hybridCloudSearchSchema } from '../router/utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud',
    },
  ],
  staticData: {
    privileges: ['PRIVATE_REGION'],
  },
  getParentRoute: () => AccountRoute,
  path: 'hybrid-cloud',
  validateSearch: (search) => hybridCloudSearchSchema.parse(search),
  component: HybridCloudComponent,
});

const LazyHybridCloudMain = lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-main" */ '../components/PrivateRegions/HybridCloudMain').then(
    ({ HybridCloudMain }) => ({
      default: HybridCloudMain,
    }),
  ),
);

function HybridCloudComponent() {
  return (
    <AuthenticatedDashboard>
      <PageFrame title="Hybrid Cloud">
        <Box sx={{ marginTop: 3 }}>
          <Suspense fallback={<LoadingIndicator />}>
            <LazyHybridCloudMain />
          </Suspense>
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
