import { Close } from '@mui/icons-material';
import { Box, Button, Card, IconButton, SxProps, Typography, Link as MuiLink } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from '../../router/Link';
import { useAccountId } from '../../routes/_account';

export const HybridCloudBanner = ({ onDismiss, sx, ...other }: { onDismiss: () => void; sx?: SxProps }) => {
  const accountId = useAccountId();

  return (
    <Card
      sx={{
        position: 'relative',
        alignItems: 'center',
        backgroundColor: '#b1bfe8',
        color: 'primary.contrastText',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        p: 4,
        ...sx,
      }}
      {...other}
    >
      <Box
        sx={{
          mr: 4,
          width: 200,
          height: 200,
          '& img': {
            height: 200,
            width: 'auto',
          },
        }}
      >
        <img alt="" src="/hybrid-cloud-rocket.svg" />
      </Box>
      <div>
        <Typography
          sx={(theme) => ({ mt: 2, color: theme.palette.mode === 'dark' ? 'primary.contrastText' : 'text.primary' })}
          variant="h4"
        >
          Introducing Qdrant Hybrid Cloud
        </Typography>
        <Box
          sx={(theme) => ({ mt: 1, color: theme.palette.mode === 'dark' ? 'primary.contrastText' : 'text.primary' })}
        >
          <Typography variant="subtitle2">
            Deploy Qdrant as a managed service in the environment of your choice, be
          </Typography>
          <Typography variant="subtitle2">it cloud, on-premise, or on the edge.</Typography>
        </Box>
        <Box sx={{ mt: 2 }} display={'flex'}>
          <MuiLink href="https://qdrant.tech/documentation/hybrid-cloud/" target="_blank" underline="none">
            <Button color="secondary" variant="contained" sx={{ mr: 1 }}>
              View Release Blog
            </Button>
          </MuiLink>
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            to="/accounts/$accountId/hybrid-cloud"
            params={{ accountId }}
          >
            Get Started
          </Button>
        </Box>
      </div>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          mt: theme.spacing(2),
          right: theme.spacing(2),
          top: 0,
        })}
      >
        <IconButton
          title="Dismiss"
          onClick={onDismiss}
          sx={(theme) => ({
            backgroundColor: '#576280',
            color: theme.palette.mode === 'dark' ? 'text.primary' : 'primary.contrastText',
            '&:hover': {
              backgroundColor: alpha('#576280', 0.8),
            },
          })}
        >
          <Close />
        </IconButton>
      </Box>
    </Card>
  );
};
