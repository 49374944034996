/* eslint-disable max-len */

export const CODE_SNIPPET_LANGS = {
  curl: 'curl',
  grpcurl: 'grpcurl',
  python: 'python',
  javascript: 'javascript',
  rust: 'rust',
  java: 'java',
  csharp: 'csharp',
  go: 'go',
};
export type CodeSnippetLang = keyof typeof CODE_SNIPPET_LANGS;

// please, always start a snippet code on new line without extra spaces,
// code inside `` will keep all the indentation in the app's view
export function getCodeSnippetTemplates(hostPlaceholder: string, token: string): Record<CodeSnippetLang, string> {
  return {
    curl: `
curl \\
    -X GET 'https://${hostPlaceholder}:6333' \\
    --header 'api-key: ${token}'
`,

    grpcurl: `
grpcurl \\
    -import-path ./lib/api/src/grpc/proto/ \\
    -proto qdrant.proto -d '{}' \\
    -H "api-key: ${token}" \\
    'https://${hostPlaceholder}:6334' \\
    qdrant.Qdrant/HealthCheck
`,
    python: `
from qdrant_client import QdrantClient

qdrant_client = QdrantClient(
    url="https://${hostPlaceholder}:6333", 
    api_key="${token}",
)

print(qdrant_client.get_collections())`,
    javascript: `
import {QdrantClient} from '@qdrant/js-client-rest';

const client = new QdrantClient({
    url: 'https://${hostPlaceholder}:6333',
    apiKey: '${token}',
});

try {
    const result = await client.getCollections();
    console.log('List of collections:', result.collections);
} catch (err) {
    console.error('Could not get collections:', err);
}`,
    rust: `
use qdrant_client::Qdrant;

#[tokio::main]
async fn main() {
  let client = Qdrant::from_url("https://${hostPlaceholder}:6334")
      .api_key("${token}")
      .build()
      .unwrap();
      
  let collections_list = client.list_collections().await;
  let _ = dbg!(collections_list);
}`,
    java: `
package org.example;

import io.qdrant.client.QdrantClient;
import io.qdrant.client.QdrantGrpcClient;

import java.time.Duration;
import java.util.List;
import java.util.concurrent.ExecutionException;

public class Main {
    public static void main(String[] args) throws ExecutionException, InterruptedException {
        QdrantClient client = new QdrantClient(
            QdrantGrpcClient.newBuilder(
              "${hostPlaceholder}",
              6334,
              true
            )
            .withApiKey("${token}")
            .build()
        );

        List<String> collections = client.listCollectionsAsync(Duration.ofSeconds(5)).get();

        System.out.printf(collections.toString());
    }
}`,
    csharp: `
using Qdrant.Client;

var client = new QdrantClient(
  host: "${hostPlaceholder}",
  https: true,
  apiKey: "${token}"
);

var collections = await client.ListCollectionsAsync();

foreach (var collection in collections) {
    Console.WriteLine(collection);
}`,
    go: `
package main

import (
	"context"
	"fmt"

	"github.com/qdrant/go-client/qdrant"
)

func main() {
	client, err := qdrant.NewClient(&qdrant.Config{
		Host:   "${hostPlaceholder}",
		Port:   6334,
		APIKey: "${token}",
		UseTLS: true,
	})
	if err != nil {
		panic(err)
	}

	collections, err := client.ListCollections(context.Background())
	if err != nil {
		panic(err)
	}

	fmt.Println(collections)
}
`,
  };
}
export const getCodeSnippet = function (host: string, token: string, type: CodeSnippetLang) {
  const codeSnippetTemplates = getCodeSnippetTemplates(host, token);
  return (codeSnippetTemplates[type] || '').trim();
};
