import { Avatar, Box, Card, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { Bedtime, OpenInNew } from '@mui/icons-material';
import { useNavigate } from '@tanstack/react-router';
import { useAccountId } from '../../routes/_account';

/**
 * @param {Object} props
 * @param {Array<import('../../services/clustersApi').Cluster>} props.clusters
 * @param {boolean} props.isLoading
 */
export const OverviewClustersWidget = (props) => {
  const { clusters, isLoading } = props;
  const accountId = useAccountId();
  const navigate = useNavigate();
  const gridItemProps = {
    sm: 4,
    xs: 12,
  };

  /* showing skeletons if loading */
  if (isLoading) {
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} {...gridItemProps}>
          <Skeleton variant={'rounded'} width={'100%'} height={118} />
        </Grid>
        <Grid item={true} {...gridItemProps}>
          <Skeleton variant={'rounded'} width={'100%'} height={118} />
        </Grid>
        <Grid item={true} {...gridItemProps}>
          <Skeleton variant={'rounded'} width={'100%'} height={118} />
        </Grid>
      </Grid>
    );
  }
  const healthyClusters = clusters.filter((cluster) => cluster?.state?.replicationStatus === 'healthy');
  const underReplicatedClusters = clusters.filter(
    (cluster) => cluster?.state?.replicationStatus === 'under_replicated',
  );
  const downClusters = clusters.filter(
    (cluster) => cluster?.state?.replicationStatus === 'down' && cluster?.state?.phase !== 'Suspended',
  );
  const unknownStateClusters = clusters.filter((cluster) => cluster?.state?.replicationStatus === 'unknown');
  const suspendedClusters = clusters.filter((cluster) => cluster?.state?.phase === 'Suspended').length;
  const clusterWithErrorNumber = underReplicatedClusters.length + downClusters.length + unknownStateClusters.length;

  /* showing widgets if not loading */
  return (
    <Grid container={true} spacing={4}>
      <Grid item={true} {...gridItemProps}>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 1,
            }}
          >
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                mr: 1,
              }}
              variant="rounded"
            >
              <Box
                sx={{
                  animation: 'pulse ease 750ms infinite',
                  borderRadius: '50%',
                  p: 0.5,
                  '@keyframes pulse': {
                    '0%': {
                      boxShadow: 'none',
                    },
                    '100%': {
                      boxShadow: (theme) => `0px 0px 0px 6px ${alpha(theme.palette.success.main, 0.1)}`,
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'success.main',
                    borderRadius: '50%',
                    height: 12,
                    width: 12,
                  }}
                />
              </Box>
            </Avatar>
            <Typography variant="h5">{healthyClusters.length}</Typography>
          </Box>
          <Typography color="textSecondary" variant="body2">
            Healthy Clusters
          </Typography>
        </Card>
      </Grid>

      <Grid item={true} {...gridItemProps}>
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 3,
          }}
        >
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 1,
              }}
            >
              <Avatar
                variant="rounded"
                sx={{
                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                  color: 'error.main',
                  mr: 1,
                }}
              >
                <WarningIcon fontSize="small" />
              </Avatar>
              <Typography variant="h5">{clusterWithErrorNumber}</Typography>
            </Box>
            <Typography color="textSecondary" variant="body2">
              Clusters Offline
            </Typography>
          </Box>
          {clusterWithErrorNumber > 0 && (
            <Box alignItems="top">
              <IconButton
                color="primary"
                onClick={() => navigate({ to: '/accounts/$accountId/clusters', params: { accountId } })}
              >
                <OpenInNew fontSize="small" cursor="pointer" />
              </IconButton>
            </Box>
          )}
        </Card>
      </Grid>
      <Grid item={true} {...gridItemProps}>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 1,
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                color: 'primary.main',
                mr: 1,
              }}
            >
              <Bedtime fontSize="small" />
            </Avatar>
            <Typography variant="h5">{suspendedClusters}</Typography>
          </Box>
          <Typography color="textSecondary" variant="body2">
            Suspended Clusters
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};
