import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useTrackInteractionEvent } from '../../../../../hooks/use-event-tracking';
import { clusterCreateSearch } from '../../../../../router/utils';
import { useAccountId } from '../../../../../routes/_account';

const ctaLabel = 'Create Production Cluster';
const title = 'Create a production cluster';

const StyledCardHeader = styled(CardHeader)({
  padding: '3px 0 2px 0',
  '& span': { fontSize: '1.25em' },
});

const StyledList = styled(List)({
  '& li': {
    padding: '2px 4px',
    '& svg': { height: '0.5em' },
    '& div': {
      '& span': { fontSize: '0.875em', color: '#A0AEC0' },
    },
  },
});

export const CreateCustomClusterCard = () => {
  const navigate = useNavigate();
  const accountId = useAccountId();

  const trackCreateClusterClick = useTrackInteractionEvent({
    label: ctaLabel,
    location: `${title} Card (Horizontal)`,
    action: 'clicked',
  });

  const handleClick = useCallback(() => {
    trackCreateClusterClick();
    void navigate({
      to: '/accounts/$accountId/clusters/create',
      params: { accountId },
      search: clusterCreateSearch,
    });
  }, [accountId, navigate, trackCreateClusterClick]);

  return (
    <Grid
      item={true}
      xs={12}
      md={6}
      sx={{
        flexGrow: 1,
      }}
    >
      <Card
        sx={{
          px: 5,
          py: 4,
        }}
      >
        <Box component="img" src="/cluster-flow-production-cluster-icon.svg" alt="Production Cluster Icon" />
        <StyledCardHeader title={title} />
        <CardContent sx={{ pt: 0, pl: 0 }}>
          <StyledList>
            <ListItem>
              <CheckIcon />
              <ListItemText primary="Support level standard (SLA)" />
            </ListItem>
            <ListItem color="secondary">
              <CheckIcon />
              <ListItemText primary="Multi-node clusters" />
            </ListItem>
            <ListItem color="secondary">
              <CheckIcon />
              <ListItemText primary="Horizontal scaling" />
            </ListItem>
            <ListItem color="secondary">
              <CheckIcon />
              <ListItemText primary="Backup and disaster recovery" />
            </ListItem>
          </StyledList>
        </CardContent>
        <CardActions sx={{ px: 0, pb: 0 }}>
          <Button variant="contained" color="secondary" onClick={handleClick}>
            {ctaLabel}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
