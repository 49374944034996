import Close from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useAccountId } from '../../routes/_account';
import { useDeleteHybridCloudEnvironmentMutation, useGetClustersByAccountIdQuery } from '../../services/clustersApi';
import { isFetchApiError, isFetchMutationError, parseFetchError } from '../../services/helpers';
import { ConfirmationDialogDangerAction } from '../Common/ConfirmationDialogDangerAction';
import { ErrorBox } from '../Common/ErrorBox';

const DialogContainer = ({
  children,
  fullScreen,
  onClose,
}: {
  fullScreen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}) => (
  <Dialog
    fullScreen={fullScreen}
    fullWidth={true}
    open={true}
    onClose={onClose}
    aria-labelledby="cluster-create-dialog-title"
    aria-describedby="cluster-create-dialog-description"
  >
    <IconButton sx={{ maxWidth: '48px', alignSelf: 'end', mt: 2, mr: 2 }} onClick={onClose}>
      <Close titleAccess="Close" />
    </IconButton>
    <Box sx={{ pt: 1, pb: 2 }}>{children}</Box>
  </Dialog>
);

export const DeletionDialog = ({
  hybridCloudEnvId,
  hybridCloudEnvName,
  onClose,
  onSuccessfulDeletion,
}: {
  hybridCloudEnvId: string;
  hybridCloudEnvName: string;
  onClose: () => void;
  onSuccessfulDeletion: () => void;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const accountId = useAccountId();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEnvironment] = useDeleteHybridCloudEnvironmentMutation();
  const {
    data: clusters,
    isError: isErrorLoadingClusters,
    isLoading: isLoadingClusters,
    refetch: refetchClusters,
  } = useGetClustersByAccountIdQuery({ accountId, privateRegionId: hybridCloudEnvId });

  const handleHybridCloudDeletion = useCallback(async () => {
    const result = await deleteEnvironment({ account_id: accountId, hybrid_cloud_env_id: hybridCloudEnvId });
    if (isFetchMutationError(result)) {
      const error = parseFetchError(result.error);
      // If E1052 error happened -> user needs to delete clusters
      if (isFetchApiError(error) && error.code === 'E1052') {
        enqueueSnackbar(
          'Before removing the Hybrid Cloud Environment you must delete its attached Qdrant database clusters',
          {
            variant: 'error',
          },
        );
      } else {
        enqueueSnackbar('There was a problem deleting the Hybrid Cloud Environment. Try again later.', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Successfully deleted the Hybrid Cloud Environment', {
        variant: 'success',
      });
      onSuccessfulDeletion();
    }
  }, [deleteEnvironment, enqueueSnackbar, accountId, hybridCloudEnvId, onSuccessfulDeletion]);

  if (isErrorLoadingClusters) {
    return (
      <DialogContainer fullScreen={fullScreen} onClose={onClose}>
        <ErrorBox retry={refetchClusters} />;
      </DialogContainer>
    );
  }

  if (isLoadingClusters) {
    return (
      <DialogContainer fullScreen={fullScreen} onClose={onClose}>
        <Box sx={{ p: 2, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress size={48} />
        </Box>
      </DialogContainer>
    );
  }

  if (clusters?.length) {
    return (
      <DialogContainer fullScreen={fullScreen} onClose={onClose}>
        <Typography variant="h5" sx={{ pb: 3, px: 3 }}>
          Deleting Hybrid Cloud Environment
        </Typography>

        {/* <Typography variant="body1" sx={{ mt: 1, mb: 2 }}> */}
        <Alert severity="info">
          Before deleting your Hybrid Cloud Environment you must delete the Qdrant database clusters attached to it.
        </Alert>
        {/* </Typography> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 2,
            pt: 3,
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Ok
          </Button>
        </Box>
      </DialogContainer>
    );
  }

  return (
    <ConfirmationDialogDangerAction
      open={true}
      onClose={onClose}
      actionName="Delete Environment"
      title="Delete Hybrid Cloud Environment"
      content="Are you sure you want to delete the environment?"
      warning="This action cannot be undone"
      actionHandler={handleHybridCloudDeletion}
      actionTarget={hybridCloudEnvName}
    />
  );
};
