import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ClusterScaleAction } from './ClusterButtons/ClusterScaleAction';
// eslint-disable-next-line max-len
import { ClusterDeploymentSummaryFeatureItems } from './ClusterSetup/ClusterDeploymentSummary/ClusterDeploymentSummaryFeatureItems';
import { Cluster } from '../../services/clustersApi';

export function UpgradeFreeToPaidBanner({ cluster, accountId }: { cluster: Cluster; accountId: string }) {
  return (
    <Card>
      <CardHeader sx={{ pb: 1 }} color="primary" title={'Get all Qdrant Cloud features'}></CardHeader>
      <CardContent sx={{ pt: 0 }}>
        <Typography sx={{ mb: 1 }}>
          You are currently using a free tier cluster. Scale up your cluster to a paid plan to get the following
          features:
        </Typography>
        <ClusterDeploymentSummaryFeatureItems isFreeTemplate={false} />
        <ClusterScaleAction
          cluster={cluster}
          accountId={accountId}
          key={4}
          trackingLocation="Cluster UpgradeFreeToPaidBanner"
          trackingLabel="UpgradeToPaid"
        >
          <Button sx={{ minWidth: 136, m: 1 }} variant="outlined" color="success">
            Scale up cluster
          </Button>
        </ClusterScaleAction>
      </CardContent>
    </Card>
  );
}
