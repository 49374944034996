import { Link, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { Link as RouterLink } from '@tanstack/react-router';
import { useAccountId } from '../../../../../routes/_account';

export const ApiKeyTips = () => {
  const accountId = useAccountId();
  const navigate = useNavigate();

  return (
    <>
      <Typography component={'p'} my={1}>
        Read more about access in our{' '}
        <Link component={RouterLink} to={'https://qdrant.tech/documentation/cloud/authentication/'} target={'_blank'}>
          documentation
        </Link>
      </Typography>
      <Typography component={'div'}>
        You can manage all your API keys in the{' '}
        <Link
          component="button"
          variant="body1"
          sx={{ pb: 0.5 }}
          onClick={() => navigate({ to: '/accounts/$accountId/data-access', params: { accountId } })}
        >
          Access section
        </Link>
      </Typography>
    </>
  );
};
