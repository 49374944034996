import { Box, Card, Skeleton } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useAccountId } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { ClusterCreateButton } from '../components/Clusters/ClusterButtons/ClusterCreateButton';
import { ClustersListTable } from '../components/Clusters/ClusterListTable/ClustersListTable';
import { ErrorBox } from '../components/Common/ErrorBox';
import { ClusterCreationDisabledWarning } from '../components/Common/WarningTexts';
import { useOnlineConnection } from '../hooks/use-online-connection';
import { useWindowFocus } from '../hooks/use-window-focus';
import { Route as ClustersRoute } from '../routes/_clusters';
import { useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { useGetFeatureFlagsQuery } from '../services/configApi';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Clusters',
    },
  ],
  getParentRoute: () => ClustersRoute,
  path: '/',
  component: ClustersComponent,
});

function ClustersComponent() {
  const accountId = useAccountId();
  const {
    data: featureFlags,
    isLoading: isFeatureFlagsLoading,
    isError: isFeatureFlagsError,
  } = useGetFeatureFlagsQuery();
  const isCreatingEnabled = featureFlags?.cluster_creation_enabled ?? !isFeatureFlagsError;
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();

  const {
    data: clusters,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useGetClustersByAccountIdQuery(
    { accountId },
    { refetchOnFocus: true, pollingInterval: isFocused && isOnline ? 10_000 : 0, refetchOnMountOrArgChange: true },
  );

  const hasClusters = clusters && clusters.length > 0;
  const clusterCreateButtonDisabled = isLoading || Boolean(isFeatureFlagsLoading) || !isCreatingEnabled;

  const clusterCreateButton = useMemo(
    () => <ClusterCreateButton key={2} accountId={accountId} disabled={clusterCreateButtonDisabled} />,
    [accountId, clusterCreateButtonDisabled],
  );

  const actionButtons = useMemo(() => {
    const buttons = [];
    if (!isCreatingEnabled) {
      buttons.push(<ClusterCreationDisabledWarning key={1} sx={{ mr: 2 }} />);
    }
    if (hasClusters) {
      buttons.push(clusterCreateButton);
    }
    return buttons;
  }, [hasClusters, clusterCreateButton, isCreatingEnabled]);

  return (
    <AuthenticatedDashboard>
      <PageFrame title={'Clusters'} buttons={actionButtons}>
        {isLoading ? (
          <Skeleton variant="rounded" width={'100%'} height={300} sx={{ mb: '2px' }} />
        ) : (
          <>
            {isError && (
              <Card style={{ padding: '20px' }} variant="outlined">
                <ErrorBox msg="There was an error fetching clusters." retry={refetch} />
              </Card>
            )}
            {!isError && hasClusters && (
              <ClustersListTable clusters={clusters} updatingList={isFetching} title="Your active clusters" />
            )}
            {!isError && !hasClusters && (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <Box>
                  <Box>{`You do not have any clusters yet${
                    !clusterCreateButtonDisabled ? ', go ahead and create one' : '.'
                  }`}</Box>
                  <Box pt={1} justifyContent={'center'} alignItems="center" display="flex">
                    {clusterCreateButton}
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
