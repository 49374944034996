import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';
import { Link } from '../../../router/Link';
import { useAuth } from '../../../router/hooks';
import { useAccountId } from '../../../routes/_account';

export const AccountMenu = () => {
  const accountId = useAccountId();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = useCallback(() => navigate({ to: '/logout' }), [navigate]);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
          cursor: 'default',
        }}
      >
        <Avatar
          src={typeof user.avatar === 'string' ? user.avatar : undefined}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1" data-sentry-mask={true}>
            {user.name ?? '-'}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <Link
          style={{ textDecoration: 'none', color: theme.palette.text.primary }}
          to="/accounts/$accountId/profile"
          params={{ accountId }}
        >
          <MenuItem>
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">Profile</Typography>} />
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </>
  );
};
