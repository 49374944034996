import { Alert, Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { NotFoundRouteProps } from '@tanstack/react-router';
import { isRouteAccessControlError } from '../../router/withRouteAccessControl';
import { isDevelopmentEnv } from '../../utils/utils';
import { PageFrame } from '../Authenticated/PageFrame';

export const NotFound = (props: NotFoundRouteProps) => (
  <PageFrame>
    <Box pt={10} display="flex" justifyContent="center">
      <Card variant="outlined">
        <CardHeader title="Sorry we couldn't find what you were looking for" />
      </Card>
    </Box>
    {isDevelopmentEnv && isRouteAccessControlError(props.data) && (
      <Stack spacing={2} marginTop={2}>
        <Alert severity="error">
          <Typography variant="h5">👋 Developer</Typography>
          {props.data.data.privileges && <li>Missing privilege(s): {props.data.data.privileges.join(', ')}</li>}
          {props.data.data.permissions && <li>Missing permission(s): {props.data.data.permissions.join(', ')}</li>}
        </Alert>
        <Alert severity="info">
          By default, when a <code>notFound()</code> error is thrown due a privilege and/or permission missing, we show
          this fallback component to you.
          <br />
          You can further customize this logic if needed, by leveraging the Route option <code>onError</code> to report
          the error and even re-throw a redirection.
        </Alert>
      </Stack>
    )}
  </PageFrame>
);
