import { CloudQueue } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createRoute } from '@tanstack/react-router';
import { useCallback, useState } from 'react';
import { Route as RootRoute } from './__root';
import { PageFooter } from '../components/Authenticated/PageFooter';
import { CalculatorForm } from '../components/Calculator/CalculatorForm';
import { CalculatorResult } from '../components/Calculator/CalculatorResult';
import { DEFAULT_QUANTIZATION, Quantization, calculatorSearchSchema } from '../components/Calculator/utils';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { Link } from '../router/Link';
import { useAuth } from '../router/hooks';
import { CloudProvider, CloudRegion } from '../services/clustersApi';
import { BookingPackageOutput } from '../utils/booking-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Calculator',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'calculator',
  validateSearch: (search) => calculatorSearchSchema.parse(search),
  component: CalculatorComponent,
});

function CalculatorComponent() {
  const { isAuthenticated } = useAuth();
  const [recommendedPackage, setRecommendedPackage] = useState<BookingPackageOutput | undefined>();
  const [requiredMemory, setRequiredMemory] = useState<number | undefined>();
  const [provider, setProvider] = useState<CloudProvider>();
  const [region, setRegion] = useState<CloudRegion>();
  const [replicas, setReplicas] = useState(1);
  const [nodes, setNodes] = useState(1);
  const [quantization, setQuantization] = useState<Quantization>(DEFAULT_QUANTIZATION);

  const viewPortHeight = document.documentElement.clientHeight;
  const theme = useTheme();
  const mdBreakpoint = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });

  const [scrollHeight, setScrollHeight] = useState(0);

  const measuredRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setScrollHeight(node.getBoundingClientRect().height);
    }
  }, []);
  const handleSubmit = useCallback(
    ({
      recommendedPackage,
      nodes,
      replicas,
      provider,
      region,
      requiredMemory,
      quantization,
    }: {
      recommendedPackage: BookingPackageOutput;
      nodes: number;
      replicas: number;
      provider: CloudProvider;
      region: CloudRegion;
      requiredMemory: number;
      quantization: Quantization;
    }) => {
      setRecommendedPackage(recommendedPackage);
      setRequiredMemory(requiredMemory);
      setProvider(provider);
      setRegion(region);
      setNodes(nodes);
      setReplicas(replicas);
      setQuantization(quantization);

      if (mdBreakpoint) {
        window.scrollTo({
          left: 0,
          top: scrollHeight,
          behavior: 'smooth',
        });
      }
    },
    [mdBreakpoint, scrollHeight],
  );

  return (
    <>
      <Box
        minHeight={viewPortHeight}
        component="main"
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            md: 'repeat(2, 1fr)',
            xs: 'repeat(1, 1fr)',
          },
          flexGrow: 1,
        }}
      >
        <Box
          ref={measuredRef}
          sx={{
            backgroundColor: 'background.paper',
            px: 6,
            py: 8,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pl: {
                lg: 15,
              },
            }}
          >
            <Box mb={6} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              {isAuthenticated ? (
                <Button component={Link} to="/overview" startIcon={<ArrowLeftIcon fontSize="small" />}>
                  Overview
                </Button>
              ) : (
                <Button component={Link} to="/" startIcon={<CloudQueue fontSize="small" />}>
                  Cloud
                </Button>
              )}
            </Box>
            <CalculatorForm onSubmit={handleSubmit} />
          </Container>
        </Box>
        <Box
          minHeight={viewPortHeight}
          sx={{
            backgroundColor: 'background.default',
            px: 6,
            pt: 8,
            pb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: '100vh',
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pr: {
                lg: 15,
              },
            }}
          >
            <Box mb={6} display="flex" alignItems="center" justifyContent="center">
              <Link to="/">
                <img width="192" height="64" src="/logo_with_text.png" className="App-logo" alt="logo" />
              </Link>
            </Box>
            <CalculatorResult
              recommendedPackage={recommendedPackage}
              nodes={nodes}
              replicas={replicas}
              selectedProvider={provider}
              selectedRegion={region}
              requiredMemory={requiredMemory}
              quantization={quantization}
            />
          </Container>
          <PageFooter />
        </Box>
      </Box>
    </>
  );
}
