import { Theme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns/format';
import { Cluster, ClusterResources, getClusterEndpoint, isClusterHybridCloud } from '../../../utils/cluster-utils';
import { isAtLeastVersion } from '../../../utils/helpers';
import { formatBytesToGigabytes, formatFloatWithSuffix, getResourceTotal, transformGibibytesToBytes } from '../helpers';

export enum Metric {
  CPU = 'cpu',
  LATENCY = 'latency',
  DISK = 'disk',
  RPS = 'rps',
  /**
   * RAM without cache
   */
  RSS = 'ram_rss',
  /**
   * RAM without cache for Hybrid Cloud clusters
   */
  RAM_QDRANT_RSS = 'ram_qdrant_rss',
  RAM_CACHE = 'ram_cache',
  /**
   * RAM including cache
   */
  RAM = 'ram',
}

export const RAM_LABEL = 'RAM (incl. caches)';

export const FIVE_MINUTES_IN_SECONDS = 60 * 5; // seconds * minutes
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const SIX_HOURS_IN_SECONDS = ONE_HOUR_IN_SECONDS * 6;
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24;
export const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7;
export const ONE_MONTH_IN_SECONDS = ONE_WEEK_IN_SECONDS * 4;

export const CHART_HEIGHT = 200;
// Add a minimum height to prevent the page to jump when loading new graphs
export const CHART_CARD_HEIGHT = CHART_HEIGHT + 35;
export const CHART_COLORS = {
  BLUE: '#1f87e6',
  RED: '#f44336',
  ORANGE: '#ff9800',
  GREEN: '#4caf50',
  PINK: '#ff5c7c',
  YELLOW: '#fad107',
};

export const RESOURCE_CHART_COLOR = {
  [Metric.CPU]: CHART_COLORS.GREEN,
  [Metric.RAM]: CHART_COLORS.PINK,
  [Metric.RSS]: CHART_COLORS.YELLOW,
  [Metric.DISK]: CHART_COLORS.BLUE,
  [Metric.RPS]: CHART_COLORS.ORANGE,
  [Metric.LATENCY]: CHART_COLORS.RED,
};

const DATE_AND_TIME_FORMAT = 'd MMM yy H:mm';
const TIME_FORMAT = 'H:mm';

/**
 * Depending on the current time range, it formats the unix date to a human readable string.
 * @param {number} value - the unix epoch
 * @param {number} timeFrame - current time frame
 */
export const formatDateForXAxis = (unixEpoch: number, timeFrame: number) => {
  if (!unixEpoch) {
    return '';
  }

  // Transform unix epoch to a milliseconds timestamp
  const timestamp = unixEpoch * 1000;
  const dateFormat =
    timeFrame > ONE_HOUR_IN_SECONDS
      ? DATE_AND_TIME_FORMAT
      : // Display seconds when the time frame is minor than 1 hour.
        timeFrame < ONE_HOUR_IN_SECONDS
        ? `${TIME_FORMAT}:ss`
        : TIME_FORMAT;

  return format(timestamp, dateFormat);
};

const getCoreFormatter =
  (fixedTo = 5) =>
  (value: number) =>
    formatFloatWithSuffix({ value, fixedTo });

const getUnitFormatter = (formatterOptions: ByteSizeFormatterOptions) => (value: number) =>
  humanReadableSizeFormatter(value, formatterOptions);

const getGbFormatter = (formatterOptions: ByteSizeFormatterOptions) => (value: number) =>
  formatBytesToGigabytes(value, formatterOptions);

export type ByteUnitStandard = 'IEC' | 'SI';
/**
 * Format bytes as human-readable text.
 *
 * According to the SI standard, there are 1000 bytes in a kilobyte.
 * There is another standard called IEC that has 1024 bytes in a kibibyte,
 * but this is only useful when measuring things that are naturally a power of two, e.g. a stick of RAM.
 */
export type ByteSizeFormatterOptions = { standard?: ByteUnitStandard; decimalPlaces?: number };

export function humanReadableSizeFormatter(
  bytes: number,
  { standard = 'IEC', decimalPlaces = 1 }: ByteSizeFormatterOptions = {},
) {
  if (bytes > Number.MAX_SAFE_INTEGER) {
    throw new RangeError('The number is too big to be represented as a JavaScript number.');
  }
  const threshold = standard === 'SI' ? 1000 : 1024;

  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`;
  }

  const units = ['KB', 'MB', 'GB', 'TB'];
  let unitIndex = -1;
  const rounding = 10 ** decimalPlaces;

  do {
    bytes /= threshold;
    ++unitIndex;
  } while (Math.round(Math.abs(bytes) * rounding) / rounding >= threshold && unitIndex < units.length - 1);

  return `${bytes.toFixed(decimalPlaces)} ${units[unitIndex]}`;
}

export const getDashboardURL = (cluster?: Cluster) => {
  if (!cluster) {
    return null;
  }
  const clusterURL = getClusterEndpoint(cluster);

  if (isClusterHybridCloud(cluster) && clusterURL.endsWith('.svc:6333')) {
    return null;
  }
  const clusterVersion = cluster.state?.version;
  if (isAtLeastVersion('1.3', clusterVersion)) {
    let url: URL;
    try {
      url = new URL('/dashboard', clusterURL);
    } catch {
      // If we fail to construct the URL due to an empty or malformed clusterURL return null
      return null;
    }
    url.port = '6333';
    return url.href;
  }
  return null;
};

const RANGE_DECIMAL_PLACES = 2;

export type MetricValues = [number, number][];
export type Metrics = {
  [Metric.LATENCY]: MetricValues;
  [Metric.RPS]: MetricValues;
};

type MetricDetail = {
  label: string;
  sideLabel?: string;
  formatter: (value: number) => string;
  rangeFormatter?: (value: number) => string;
};

type MetricsDetails = {
  [K in Metric]: MetricDetail;
};
export const METRICS_DETAILS: MetricsDetails = {
  [Metric.CPU]: {
    label: 'CPU',
    formatter: getCoreFormatter(),
    rangeFormatter: getCoreFormatter(RANGE_DECIMAL_PLACES),
  },
  [Metric.RAM]: {
    label: RAM_LABEL,
    sideLabel: 'RAM',
    formatter: getUnitFormatter({ decimalPlaces: 2 }),
    rangeFormatter: getGbFormatter({ decimalPlaces: RANGE_DECIMAL_PLACES }),
  },
  [Metric.RSS]: {
    label: 'RAM',
    formatter: getUnitFormatter({ decimalPlaces: 2 }),
    rangeFormatter: getGbFormatter({ decimalPlaces: RANGE_DECIMAL_PLACES }),
  },
  [Metric.RAM_QDRANT_RSS]: {
    label: 'RAM',
    formatter: getUnitFormatter({ decimalPlaces: 2 }),
    rangeFormatter: getGbFormatter({ decimalPlaces: RANGE_DECIMAL_PLACES }),
  },
  [Metric.RAM_CACHE]: {
    label: 'RAM',
    formatter: getUnitFormatter({ decimalPlaces: 2 }),
    rangeFormatter: getGbFormatter({ decimalPlaces: RANGE_DECIMAL_PLACES }),
  },
  [Metric.DISK]: {
    label: 'Disk',
    formatter: getUnitFormatter({ decimalPlaces: 2 }),
    rangeFormatter: getGbFormatter({ decimalPlaces: RANGE_DECIMAL_PLACES }),
  },
  [Metric.RPS]: {
    label: 'Requests',
    formatter: (value: number) => formatFloatWithSuffix({ value, fixedTo: 2, suffix: 'rps' }),
  },
  [Metric.LATENCY]: {
    label: 'Latency',
    formatter: (value: number) => formatFloatWithSuffix({ value, fixedTo: 2, suffix: 'ms' }),
  },
};

export const TIME_FRAMES = [
  {
    value: FIVE_MINUTES_IN_SECONDS,
    label: '5m',
  },
  {
    value: ONE_HOUR_IN_SECONDS,
    label: '1h',
  },
  {
    value: SIX_HOURS_IN_SECONDS,
    label: '6h',
  },
  {
    value: ONE_DAY_IN_SECONDS,
    label: '1d',
  },
  {
    value: ONE_WEEK_IN_SECONDS,
    label: '1w',
  },
  {
    value: ONE_MONTH_IN_SECONDS,
    label: '1mon',
  },
];
// Set 1 hour as the default time frame.
export const DEFAULT_TIME_FRAME_INDEX = 1;

/**
 * ApexCharts doesn't provide a type for the options object in the custom tooltip function.
 * So we need to define it here based on usage and guessing after debugging.
 */
type TooltipOptions = {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    globals: {
      colors: string[];
    };
  };
};

const getCustomTooltipFunc =
  (metrics: ChartMetricData[], dataSeries: ApexAxisChartSeries) => (options: TooltipOptions) => {
    const { series, dataPointIndex, w } = options;
    // Calculate the total value at the given data point
    const total: number = series.reduce((acc: number, curr: number[]) => acc + curr[dataPointIndex], 0);

    // Create the custom tooltip HTML (by opening the wrapper div)
    let tooltipHtml = `<div>`;

    // Add each metric value to the tooltip
    series.forEach((s: number[], i: number) => {
      const value = s[dataPointIndex];
      const formattedValue = METRICS_DETAILS[metrics[i].name].formatter(value);

      tooltipHtml += `
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-marker" style="background-color: ${w.globals.colors[i]};"></span>
        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
          <div class="apexcharts-tooltip-y-group">
            <span class="apexcharts-tooltip-text-y-label">${dataSeries[i].name}</span>
            <span class="apexcharts-tooltip-text-y-value">${formattedValue}</span>
          </div>
        </div>
      </div>`;
    });

    // Add total usage to the tooltip
    tooltipHtml += `
    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
      <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label">Total:</span>
          <span class="apexcharts-tooltip-text-y-value">${METRICS_DETAILS[metrics[0].name].formatter(total)}</span>
        </div>
      </div>
    </div>`;

    // Close the tooltip wrapper div
    tooltipHtml += '</div>';

    return tooltipHtml;
  };

export type ChartMetricData = {
  name: Metric;
  label?: string;
  color: string;
  max: number;
  hideLeftBar?: boolean;
};
type ChartOptions = {
  metrics: ChartMetricData[];
  timeFrame: number;
  xAxisRange: {
    since: number;
    until: number;
  };
  theme: Theme;
  stacked?: boolean;
  customTooltip?: {
    dataSeries: ApexAxisChartSeries;
  };
};

/**
 * Creates an Apex charts options object.
 */
export const getChartOptions = (options: ChartOptions): ApexOptions => {
  const {
    metrics,
    timeFrame,
    xAxisRange: { since, until },
    theme,
    stacked,
    customTooltip,
  } = options;
  return {
    chart: {
      type: 'area',
      background: 'transparent',
      stacked,

      toolbar: {
        show: false,
        tools: {
          zoom: false,
          selection: false,
        },
      },
      events: {
        // This handler is required to fix sizing issue on Chrome/linux.
        // Taken from: https://github.com/apexcharts/react-apexcharts/issues/187
        // The change should be invisible to the users, not affected by the problem.
        beforeMount(chart: { windowResizeHandler: CallableFunction }) {
          chart.windowResizeHandler();
        },
      },
    },
    colors: metrics.map(({ color }) => color),
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
    dataLabels: {
      enabled: false,
    },
    // Define formatters for the values displayed on hover.
    tooltip: !customTooltip
      ? {
          y: metrics.map(({ name }) => ({
            formatter: METRICS_DETAILS[name].formatter,
          })),
        }
      : {
          custom: getCustomTooltipFunc(metrics, customTooltip.dataSeries),
        },
    legend: {
      show: true,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: 'straight',
      lineCap: 'butt',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        // Define a formatter for the X axis range labels.
        formatter: (value: string) => formatDateForXAxis(Number(value), timeFrame),
        style: {
          colors: theme.palette.text.secondary,
        },
        rotate: 0,
      },
      type: 'datetime',
      min: since,
      max: until,
    },
    yaxis: metrics.map(({ name, max, hideLeftBar }, index) => ({
      // Make the second Y axis render on the opposite side.
      show: !hideLeftBar,
      ...(index % 2 !== 0 ? { opposite: true } : {}),
      tickAmount: 4,
      title: {
        text: METRICS_DETAILS[name].sideLabel ?? METRICS_DETAILS[name].label,
      },
      labels: {
        // Define a formatter for the Y axis range labels.
        formatter: (value) => {
          const rangeFormatter = METRICS_DETAILS[name].rangeFormatter ?? METRICS_DETAILS[name].formatter;
          return rangeFormatter(value);
        },
        style: {
          colors: theme.palette.text.secondary,
        },
        minWidth: 40, // Required to align the Y axis of graphs with different labels.
      },
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      min: 0,
      max,
    })),
  };
};

/**
 * Obtains the maximum possible number for the metrics, based on the cluster config.
 */
export const getMaxResources = (resources: ClusterResources) => {
  const maxCPU = getResourceTotal(resources?.[Metric.CPU]);
  const maxRAM = getResourceTotal(resources?.[Metric.RAM]);
  const maxDisk = getResourceTotal(resources?.[Metric.DISK]);

  return {
    [Metric.CPU]: maxCPU ? maxCPU / 1000 : maxCPU,
    // We transform the values from Gibibytes, instead of Gigabytes for total/max resources
    // because it is the unit used in the cluster resources.
    [Metric.DISK]: maxDisk ? transformGibibytesToBytes(maxDisk) : maxDisk,
    [Metric.RAM]: maxRAM ? transformGibibytesToBytes(maxRAM) : maxRAM,
  };
};
