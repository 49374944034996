import { Book } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Typography } from '@mui/material';
import { useMemo, useCallback, useState, Fragment } from 'react';
import { CreateProgrammaticAccessKeyDialog } from './CreateProgrammaticAccessKeyDialog';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { Plus } from '../../icons/plus';
import { useAccountId } from '../../routes/_account';
import { isFetchMutationError } from '../../services/helpers';
import {
  ProgrammaticAccessKeyRead,
  useGetProgrammaticAccessKeysQuery,
  useDeleteProgrammaticAccessKeyMutation,
} from '../../services/iamApi';
import { CardTitleWithLoadingIndicator } from '../Common/CardTitleWithLoadingIndicator';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { ErrorBox } from '../Common/ErrorBox';
import { ColumnType, QdrantTable, RowAction, RowType } from '../Common/QdrantTable';

const ACCESS_KEYS_COLUMNS = [
  { title: 'Created At', dataKey: 'createdAt' },
  { title: 'Qdrant Cloud API Keys', dataKey: 'programmaticAccessKey' },
];

const FEEDBACK_MSG_CONTAINER_STYLE = {
  pt: 1,
  pl: 2,
  pb: 2,
};

const ProgrammaticAccessKeyTableContainer = ({
  rows,
  columns,
  actions,
}: {
  rows: RowType<ProgrammaticAccessKeyRead>[];
  columns: ColumnType<ProgrammaticAccessKeyRead>[];
  actions: RowAction<ProgrammaticAccessKeyRead>[];
}) => {
  if (!rows.length) {
    return (
      <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
        <Typography sx={{ mb: 1 }}>No access keys available.</Typography>
      </Box>
    );
  }

  return (
    <Box data-sentry-mask={true}>
      <QdrantTable<ProgrammaticAccessKeyRead>
        columns={columns}
        rows={rows}
        rowActions={actions}
        pagination={true}
        ariaLabel="Qdrant Cloud API Keys"
      />
    </Box>
  );
};

export const ProgrammaticAccessKeys = () => {
  const accountId = useAccountId();
  const { enqueueSnackbar } = useSnackbar();
  const [createKeyDialogOpen, setCreateKeyDialogOpen] = useState(false);

  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();

  const {
    data: keys,
    isLoading: loadingKeys,
    isFetching: fetchingKeys,
    isError: errorLoadingKeys,
    refetch: refetchKeys,
  } = useGetProgrammaticAccessKeysQuery({ accountId });

  const [deleteProgrammaticAccessKey, { isLoading: deletingKey }] = useDeleteProgrammaticAccessKeyMutation();

  const handleDeleteKey = useCallback(
    async (keyId: string) => {
      const result = await deleteProgrammaticAccessKey({ accountId, programmaticAccessKeyId: keyId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem deleting the PA key. Try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar('PA key deleted successfully.', { variant: 'success' });
      }
    },
    [accountId, enqueueSnackbar, deleteProgrammaticAccessKey],
  );

  const keysRows: RowType<ProgrammaticAccessKeyRead>[] = useMemo(() => {
    if (!keys) {
      return [];
    }
    // filter out hybrid cloud api keys
    return keys
      .filter((key) => key.creator_type !== 'SYSTEM')
      .map((key) => {
        const { id, prefix, created_at } = key;
        return {
          prefix,
          createdAt: created_at,
          programmaticAccessKey: `${id}|${prefix}***************`,
          rowData: key,
        };
      });
  }, [keys]);

  const keyRowActions = useMemo(
    () => [
      {
        name: 'Delete key',
        getHandler: (data: ProgrammaticAccessKeyRead) => () =>
          showConfirmation({
            actionName: 'Delete',
            title: 'Delete Access Key',
            content: 'Are you sure you want to delete this access key?',
            actionHandler: () => handleDeleteKey(data.id),
            withConfirm: false,
          }),
        isDisabled: () => deletingKey,
      },
    ],
    [handleDeleteKey, deletingKey, showConfirmation],
  );

  const handleOpenCreateDialog = useCallback(() => {
    setCreateKeyDialogOpen(true);
  }, []);

  const handleCloseCreateDialog = useCallback(() => {
    setCreateKeyDialogOpen(false);
  }, []);

  return (
    <Fragment>
      <Card sx={{ marginTop: 2 }} title={`Qdrant Cloud API Keys`}>
        <CardHeader
          title={
            <CardTitleWithLoadingIndicator title={'Qdrant Cloud API Keys'} isLoading={!loadingKeys && fetchingKeys} />
          }
          action={
            <>
              <Button
                key="open-api-docs"
                startIcon={<Book fontSize="small" />}
                variant="outlined"
                href="/pa/v1/docs"
                target="_blank"
                sx={{ mr: 1 }}
              >
                API docs
              </Button>
              <Button
                key="create-api-key"
                startIcon={<Plus fontSize="small" />}
                variant="contained"
                onClick={handleOpenCreateDialog}
              >
                Create
              </Button>
            </>
          }
        />

        {errorLoadingKeys ? (
          <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
            <ErrorBox retry={refetchKeys} />
          </Box>
        ) : (
          <ProgrammaticAccessKeyTableContainer columns={ACCESS_KEYS_COLUMNS} rows={keysRows} actions={keyRowActions} />
        )}
      </Card>
      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
      <CreateProgrammaticAccessKeyDialog
        accountId={accountId}
        onClose={handleCloseCreateDialog}
        open={createKeyDialogOpen}
      />
    </Fragment>
  );
};
