import { Box, Button, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { DASHBOARD_LAYOUT_GAP, AuthenticatedDashboardEmpty } from './AuthenticatedDashboard';

const styleOverrides = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const AccountCatchRender = () => (
  <>
    <Typography variant="h5" component="p" sx={{ textAlign: 'center', mb: 2 }}>
      We're really sorry, but something went wrong. Our team has been notified and is working hard to fix this.
      <br />
      Please try again later. We appreciate your patience and understanding.
    </Typography>
    <Button
      variant="outlined"
      onClick={() => {
        window.location.reload();
      }}
    >
      Try again
    </Button>
  </>
);

const AccountDashboardCatchFallback = () => (
  <AuthenticatedDashboardEmpty>
    <Box sx={{ ...styleOverrides, height: `calc(100vh - ${DASHBOARD_LAYOUT_GAP})` }}>
      <AccountCatchRender />
    </Box>
  </AuthenticatedDashboardEmpty>
);

const AccountCatchFallback = () => (
  <Box sx={{ ...styleOverrides, height: `calc(100vh - ${DASHBOARD_LAYOUT_GAP})` }}>
    <AccountCatchRender />
  </Box>
);

export const AccountCatchBoundary = ({ children }: { children: ReactNode }) => (
  <Sentry.ErrorBoundary fallback={AccountCatchFallback}>{children}</Sentry.ErrorBoundary>
);

export const AccountDashboardCatchBoundary = ({ children }: { children: ReactNode }) => (
  <Sentry.ErrorBoundary fallback={AccountDashboardCatchFallback}>{children}</Sentry.ErrorBoundary>
);
