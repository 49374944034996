import * as Sentry from '@sentry/react';
import { memo } from 'react';
import { useAnalyticsIdentification } from '../../../hooks/use-event-tracking';
import { useSugerRegistration } from '../../../hooks/use-suger-registration';
import { useAccountUser } from '../../../routes/_account';

/**
 * This component is used to update the user data in Sentry, Segment, and other services.
 * With memo() we make sure that this component doesn't re-render on every route change.
 */
export const AccountUserHooks = memo(function AccountUserUpdates() {
  const {
    id: userId,
    account: { id: accountId },
  } = useAccountUser();

  Sentry.setUser({ id: userId, accountId });
  useAnalyticsIdentification(userId);
  useSugerRegistration(accountId);

  return null;
});
